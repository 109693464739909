.case_box{
    width: 100%;
    header,h2 {
        margin-bottom: 0px;
    }
}
.case_list{
    margin-top: 5px;
    li{
        max-width: 245px;
        list-style: none;
        margin-right: 2.5px;
        a{
            @include aLink();
            text-decoration: none;
            &:hover{
                opacity: .5 ;
            }
        }
    }
}
.case_list_img{
    width: 100%;
    max-width: 230px;
    margin-bottom: 18px;
    height: 0;
    padding-bottom: 61.23%;
}
.case_list_txt{
    width: 95%;
    margin: auto;
    line-height: 1.3;
    h3{
        font-weight: bold;
        font-size: pxToEm(16);
        color: #c60a26;
    }
    p{
        font-size: pxToEm(14);
        color: $black;
        @include clamp(1.5,1);
    }
}

.cosmetologyPage{
    .item_nav>ul>li.active>a{
        background-color: rgba(255, 255, 255, 0);
    }
    .item_btn a{
        color: #003126;
        &:hover{
            color: #c60a26;
        }
    }

    .hit .item_btn li.active a{
        color: #c60a26;
        background-color: #fff;
    }
}



@include media-breakpoint-down(sm) {
    .case_list li,.case_list_img{
        max-width: 100%;
    }
    .case_list_txt{
        h3{
            font-size: pxToEm(16);
            margin-bottom: 0;
        }
        p{
            font-size: pxToEm(15);   
        }
    }
}