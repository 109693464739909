.index_Page {

  main[role=main] {
    padding-bottom: 0;
    background: url(~images/team_bg.png) top left;
  }
}

.banner {
  a {
    display: block;
    width: 100%;
    // height: 0;
    height: 406px;
    // padding-bottom: 29.007%;
    @include screen(768px) {
      height: 0;
     padding-bottom: 29.007%;
    }
  }

  .slick-prev,
  .slick-next {
    transition: .4s;
    width: 40px;
    height: 40px;
    background: #ca1130;
    border-radius: 99px;
    margin: -4px 0 0 15px;
    outline: 0;
    z-index: 2;

    &:hover {
      background: #aaaaaa;
    }
  }

  .slick-prev {
    left: 2%;

    &::before {
      content: '';
      display: block;
      width: 7px;
      height: 13px;
      background: url(~svg/arrw_l.svg);
      margin: -4px 0 0 15px;
    }
  }

  .slick-next {
    right: 2%;

    &::before {
      content: '';
      display: block;
      width: 9px;
      height: 14px;
      background: url(~svg/arrw_r.svg);
      margin: -1px 0 0 15px;

    }
  }

  .slick-dots {
    margin: -31px 0 0;
    max-width: 1051px;
    left: 50%;
    transform: translateX(-50%);
    text-align: left;

    li {
      margin: 0 13px;

      button:before {
        background: #eeeeee;
        width: 12px;
        height: 12px;
      }

      &.slick-active button:before {
        background: #b8a68c;
      }
    }
  }
}

.in-title {
  color: #ca1130;
  font-size: pxToEm(28);
  font-weight: bold;
  margin-bottom: 20px;
  padding-bottom: 12px;
  line-height: 1.5;

  a {
    color: #ca1130;
    text-decoration: none;

    &:hover {
      opacity: 0.7;
    }
  }

  &::after {
    display: block;
    content: '';
    width: 115px;
    height: 2px;
    background: #eb6100;
  }
}

.in_arrw {
  position: absolute;
  top: 42px;
  right: 0;
  z-index: 1;
  font-size: pxToEm(18);
  font-weight: bold;

  a {
    color: #c60a26;
    text-decoration: none;

    &:after {
      transition: .4s;
      content: '';
      width: 25px;
      height: 25px;
      background: url(~images/btn-arrw.png) no-repeat;
      background-size: cover;
      display: inline-block;
      vertical-align: middle;
      position: relative;
      top: -3px;
      left: 5px;

    }

    &:hover {
      &:after {
        left: 10px;
      }
    }
  }


}

// 主治項目
.in_item_1 {
  position: relative;
  width: 100%;
  max-width: 1141px;
  margin: 0 auto;
  padding: 23px 0 40px 0;

  ul {
    // display: flex;
  }

  li {
    list-style: none;
    position: relative;
    float: left;
    transition: 0.5s;
    position: relative;
    width: 16.65%;
    height: 430px;
    max-width: 680px;
    overflow: hidden;

    &.min {
      width: 9%;
      -webkit-filter: blur(3px);

      .in_item_content {
        width: 155%;
        opacity: 0;
      }
    }

    &.active {
      width: 54%;

      .in_item_content {
        width: 100%;
        bottom: 0;
        opacity: 1;
      }
      .in_item_content .in_item_text{
        top: 4px;
      }
    }

    a {
      display: block;
      width: 100%;
      height: 100%;
    }

    // &:hover{
    //     width: 50%;

    // }
  }

  .in_item_img {
    position: absolute;
    width: 100%;
    height: 100%;
    background-position: center left !important;
  }

  .in_item_content {
    transition: .5s;
    position: absolute;
    width: 100%;
    bottom: -45px;
    background: rgba($white, 0.5);
    z-index: 1;
    padding: 14px 10px 13px 10px;

    h3 {
      font-size: pxToEm(30);
      font-weight: bold;
      padding-bottom: 8px;
      color: #000000;
      line-height: 1.5;

      &::after {
        content: "";
        display: block;
        width: 90px;
        height: 4px;
        background: #26a083;
      }
    }

    .in_item_text {
      color: #000000;
      font-size: pxToEm(16);
      height: 43px;
      overflow: hidden;
      line-height: 1.5;
      position: relative;
      top: 8px;
    }
  }
}

// 主治項目
.in_item_2 {
  max-width: 1141px;
  margin: 0 auto;
  padding: 29px 0 80px 0;

  .in-title {
    margin-bottom: 0;
  }

  .slider-nav {
    //width: 95%;
    width:1140px;
    margin: 18px auto 0 auto;
    @include screen(1140px) {
       width:980px;
    }
    @include screen(800px) {
       width:95%;
    }
    li {
      cursor: pointer;
      transition: 0.4s;
      margin: 0 12px;
      opacity: 0.5;
      &:hover{
        opacity: 1;
      }
    }

    .slick-current.slick-active li,.slick-current.slick-center li{
      opacity: 1;
    }
  }

  .slick-prev {
    width: 22px;
    height: 30px;
    background: url(~images/btn-l.png) top left no-repeat;
    background-size: cover;
  }

  .slick-next {
    width: 22px;
    height: 30px;
    background: url(~images/btn-r.png) top left no-repeat;
    background-size: cover;
  }
}

//最新消息 媒體
.in_item_3 {
  padding: 0 0 60px 0;
  width: 100%;
  background-size: cover;
}

.in_nms_box {
  width: 90%;
  max-width: 1150px;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.in_news_box {
  width: 52%;
  max-width: 572px;
  position: relative;

  .in_arrw {
    top: 31px;
    right: 13px;
  }

  .more_btn {
    display: none;
    position: absolute;
    top: 12px;
    right: 0;
    color: $blue_title;

    &.active {
      display: block;
    }

    i {
      background: $blue_title;

      &:before,
      &:after {
        background: $blue_title;
      }
    }
  }

  .news_tag {
    width: 100%;
    display: flex;
    // margin-bottom: 45px;
    position: relative;
    top: 20px;
    font-weight: bold;

    a {
      width: 146px;
      height: 65px;
      border-radius: 14px;
      background: #f6f6f6;
      font-size: pxToEm(22);
      color: #b2b2b2;
      padding: 13px 0 0 0;
      text-align: center;

      &:not(:last-child) {
        margin-right: 1%;
      }

      &.active {
        background: #fff;
      }
    }
  }

  .new_btn {
    display: block;
    text-decoration: none;

    &::before {
      content: '';
      display: inline-block;
      width: 21px;
      height: 21px;
      background: url(~images/innws1_1.png) top left no-repeat;
      background-size: cover;
      margin-right: 5px;
    }

    &.active {
      color: #cb102a;

      &::before {
        background: url(~images/innws1_2.png) top left no-repeat;
      }
    }
  }

  .media_btn {
    display: block;
    text-decoration: none;

    &::before {
      content: '';
      display: inline-block;
      width: 25px;
      height: 21px;
      background: url(~images/innws2_1.png) top left no-repeat;
      background-size: cover;
      margin-right: 5px;
    }

    &.active {
      color: #cb102a;

      &::before {
        background: url(~images/innws2_2.png) top left no-repeat;
      }
    }
  }

  .active_btn {
    display: block;
    text-decoration: none;

    &::before {
      content: '';
      display: inline-block;
      width: 21px;
      height: 21px;
      background: url(~images/innws3_1.png) top left no-repeat;
      background-size: cover;
      margin-right: 5px;
    }

    &.active {
      color: #cb102a;

      &::before {
        background: url(~images/innws3_2.png) top left no-repeat;
      }
    }
  }

  .news_content {
    position: relative;
    z-index: 1;
    width: 100%;
    display: none;
    background: #fff;
    border-radius: 14px;
    padding: 5% 0 1% 0;

    &.active {
      display: block;
    }
  }

  li {
    width: 100%;
    list-style: none;
    position: relative;

    a {
      display: flex;
      align-items: end;
      text-decoration: none;
      padding: 2% 5%;

      &:hover {
        background: #e9f2ff;
      }
    }

    &:after {
      transition: .2s;
      display: block;
      content: '';
      position: absolute;
      top: 41%;
      right: 4%;
      width: 9px;
      height: 16px;
      background: url(~images/arrw_3.png) no-repeat;
      background-size: contain;
      transform: translateX(-50%);
    }
  }

  .new_title {
    color: #5a7dac;
    font-size: pxToEm(14);
    width: 117px;
    margin-right: 23px;
    letter-spacing: 0;
    position: relative;
    top: 4px;
    text-align: center;

    .ne1,
    .ne2,
    .ne3,
    .ne4 {
      min-width: 82px;
      border-radius: 0px;
    }

    .ne3 {
      color: #fe8e3f;
      background: #fff;
      border: 1px solid #fe8e3f;
    }

    .ne4 {
      background: #097c25;
    }
  }

  .new_time {
    font-weight: bold;
    color: #afafaf;
    margin-bottom: 5px;
  }

  .in_new_txt {
    width: 60%;

    h2 {
      font-size: pxToEm(18);
      color: #1a7562;
      @include clamp(1.5, 1);
    }

    p {
      font-size: pxToEm(14);
      color: #343434;
      @include clamp(1.5, 1);
    }
  }

}

// 診所資訊

.in_information_box {
  width: 50%;
  max-width: 526px;
}

.in_information_content {
  display: none;
  border-radius: 14px;
  padding: 4.5% 1%;
  background: #fff;

  img {
    width: 100%;
    max-width: 506px;
    margin: auto;
    display: block;
  }
}

.in_item3 #top-menu-ul {
  margin-bottom: 0
}

#top-menu-ul .item_menu_Box li {
  height: 46px;

  &:not(:last-child) {
    margin-right: 2%;
  }

  &.active a {
    color: #005846;
  }

  a {
    text-decoration: none;
    position: relative;
    display: block;
    line-height: 1;
    letter-spacing: .6px;
    font-size: pxToEm(20);
    color: #b2b2b2;
    background: #fff;
    border-radius: 10px;
    width: 110px;
    height: 46px;
    line-height: 46px;

  }

}

#top-menu-ul .item_Menu {
  margin: 0 auto;
  overflow: hidden;
  height: 55px;
  position: relative;
  top: -12px;
  @include screen(586px) {
    display: block;
    height: 55px;
  }
  ::-webkit-scrollbar {
    opacity: 0;
    display: none;
    width: 3px;
    height: 3PX;
    @include screen(586px) {
      display: block;
    }
  }
  
  ::-webkit-scrollbar-track {
    display: none;
    opacity: 0;
    background-color: #e8e8e8;
    border-radius: 10px;
    @include screen(586px) {
      display: block;
    }
  }
  
  ::-webkit-scrollbar-thumb {
    display: none;
    opacity: 0;
    border-radius: 10px;
    background-color: #009a85;
    @include screen(586px) {
      display: block;
    }
  }
}

#top-menu-ul .item_menu_Box {
  width: 90%;
  margin: auto;
  overflow: hidden;
  overflow-x: scroll;
  position: relative;
}

#top-menu-ul .item_menu_Box .item_menu_list {
  white-space: nowrap !important;
  padding-bottom: 0;
  text-align: center;
  font-size: 0;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.in_item3 #top-menu-ul .item_menu_Box li a div img,
.in_item3 #top-menu-ul .item_Menu a div img {
  width: 100%
}

.flex-direction-nav {
  position: relative;
  display: none;

  a {
    display: block;
    width: 18px;
    height: 18px;
    position: absolute;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    top: -33px;
    z-index: 5;
  }

  a.flex-prev {
    content: "";
    width: 0;
    height: 0;
    border-color: transparent transparent transparent transparent;
    border-style: solid;
    border-width: 8.5px 11px 8.5px 0;
    left: 0px;
    display: block;
    width: 30px;
    height: 46px;
    background: url(../images/btn_l.png) center no-repeat;
  }

  a.flex-next {
    content: "";
    width: 0;
    height: 0;
    border-color: transparent transparent transparent transparent;
    border-style: solid;
    border-width: 8.5px 0 8.5px 11px;
    right: 0px;
    display: block;
    width: 30px;
    height: 46px;
    background: url(../images/btn_r.png) center no-repeat;
  }
}

.open_flexslider .flex-direction-nav {
  display: block;
}

.in_item3 #top-menu-ul a:hover {
  opacity: .7
}

.in_information_content {
  display: none;

  &.active {
    display: block;
  }
}

// 尖端設備
.in_item_4 {
  position: relative;
  width: 90%;
  max-width: 1150px;
  margin: 0 auto 48px auto;

  .in_arrw {
    top: 13px;
  }

  >ul .slick-list{
    
    li {
      overflow: hidden;
      border-radius: 5px;
      position: relative;
      list-style: none;
      max-width: 33%;
      max-width: 360px;
      position: relative;
      list-style: none;
      width: 33%;
      height: 0;
      // padding-bottom: 26.0881%;
      padding-bottom: 78.125%;
      max-width: 360px;
  
      &:not(:last-child) {
        margin-right: 3%;
      }
  
      a:hover {
        .in_equipment-img {
          transform: scale(1.1);
        }
  
        .in_equipment_text {
          opacity: .5;
        }
      }
    }
  }
  .slick-slide{
    margin: 0 8px;
  }
  .slick-dots li{
    width: 42px;
    height: 7px!important;
    padding: 0;
    border-radius: 0;

  }


  .in_equipment-img {
    transition: .4s;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  .in_equipment_text {
    transition: .4s;
    font-weight: bold;
    width: 85%;
    position: absolute;
    bottom: 5px;
    left: 0;
    color: #000;
    font-size: pxToEm(18);
    background: rgba($white, 0.7);
    z-index: 2;
    padding: 9px 2%;
    line-height: 1.4;
  }
}

// 影音
.in_video_box {
  position: relative;
  width: 52%;
  max-width: 596px;

  .in_arrw {
    top: 13px;
  }

  li a {
    margin-top: 17px;
    display: block;
    color: #000;
    text-decoration: none;

    @include clamp(1.5, 2);

    &:hover {
      opacity: .5;
    }
  }

  .slick-slide {
    margin: 0 10px;
  }

  .youtobe_box {
    position: relative;
    width: 100%;
    height: 180px;

    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
}


.in_item_5 {
  padding-bottom: 30px;

  li {
    list-style: none;
  }

  .slick-list {
    margin-bottom: 0;
  }

  .slick-dots {
    margin-top: 0px;
    text-align: left;

    li {
      background: none !important;
      box-shadow: none !important;
      padding: 0 !important;
      border-radius: 0 !important;

    }


  }

  .case_list_img {
    padding-bottom: 73.23%;
  }

  .in_case_box {
    position: relative;
    width: 52%;
    max-width: 441px;

    .in_arrw {
      top: 13px;
    }

    .slick-slide {
      margin: 0 10px;
    }

    .slick-dots {
      margin-top: 39px;
    }

    li {
      border-radius: 15px;
      background: #fff;
      overflow: hidden;
      padding-bottom: 11px;
      box-shadow: 2px 2px 3px 1px rgba(0, 0, 0, 0.2);

      a {
        // display: none;
        text-decoration: none;

        &:hover {
          .case_list_img {
            transform: scale(1.2);
          }

          .case_list_txt {
            opacity: .7;
          }
        }
      }
    }

    .case_list_img {
      transition: .4s;
      margin-bottom: 9px;
    }

    .case_list_txt {
      width: 90%;
    }

    .case_list_txt h3 {
      font-size: pxToEm(14);
      font-weight: bold;
      color: #000000;
      @include clamp(1.5, 1);

    }

    .case_list_txt p {
      font-size: pxToEm(14);
      color: #e10025;
      @include clamp(1.5, 1);

    }
  }


}


@include screen(1211px) {

  .in_item_1,
  .in_item_2,
  .in_nms_box {
    width: 95%;
  }

  .in_item_2 {
    padding-bottom: 40px;
  }

  .in_news_box,
  .in_information_box {
    width: 48%;
  }

  .in_news_box .news_tag a {
    width: 115px;
    font-size: pxToEm(15);
  }

  #top-menu-ul .item_menu_Box li a {
    font-size: pxToEm(15);
  }

  .in_item_5 {
    padding-bottom: 80px;
  }
}

@include media-breakpoint-down(md) {
  .index_Page main[role=main] {
    margin-top: 70px;
  }

  .more_btn {
    font-size: pxToEm(16);

    i {
      width: 25px;

      &:after,
      &:before {
        width: 9px;
      }

      &:before {
        top: -3px;
      }

      &:after {
        bottom: -3px;
      }
    }
  }

  .in_case_box ul {
    width: 49%;
  }

  .in_item_2 {
    height: auto;
  }

  .in_doctors_box {
    flex-wrap: wrap;

    .in_doctors_list,
    .in_doctors_slider {
      width: 100%;
    }

    .in_doctors_slider {
      max-width: 100%;

      .slick-dots {
        padding-right: 0;
        text-align: left;
        padding-left: 15px;
      }
    }

    .slick-slide img {
      width: 100%;
    }

  }

  .case_list .slick-dots li button {
    width: 30px !important;
  }

  .in_item_3 {

    .slick-slider,
    .slick-dots {
      left: 0px;
    }

    .video_list .slick-slide li {
      margin: 0;
      max-width: 229px;
    }

    .slick-dots li button {
      width: 30px;
    }

    .video_txt {
      font-size: pxToEm(15);
    }
  }

  .in_work_box {
    .case_list_img {
      max-width: 220px;
    }
  }

  .in_nms_box {
    flex-wrap: wrap;
  }

  .in_news_box {
    width: 100%;
    max-width: 100%;
  }

  .in_facebook {
    width: 100%;
    max-width: 383px;
    margin: auto;

    iframe {
      height: 400px;
    }
  }

  .features_img {
    width: 180px;
    height: 180px;
  }

  #top-menu-ul {
    margin: 0 auto;
  }

  .in_item3 #top-menu-ul .item_Menu,
  .in_item3 #top-menu-ul .item_menu_Box li {
    height: 36px;
  }

  .in_item3 #top-menu-ul .item_Menu,
  .in_item3 #top-menu-ul .item_menu_Box li {
    height: 36px;
  }

  #top-menu-ul .item_menu_Box li a {
    margin: 0 20px 0 0;
  }

  .in_item_1,
  .in_item_2,
  .in_nms_box {
    width: 92%;
  }

  .in_item_1 li {
    width: 33.2%;
  }

  .slider-for {
    width: 100%;

    img {
      width: 100%;
    }
  }

  .in_item_2 {
    width: 100%;

    .in-title {
      padding-left: 4%;
      margin-bottom: 21px;
    }

    .in_arrw {
      right: 5%;
    }
  }

  .in_item_1 li.active,
  .in_item_1 li.min {
    -webkit-filter: blur(0px);
    width: 33.2%;
  }

  .in_item_1 li.active .in_item_content {
    // bottom: -45px;
  }

  .in_item_1 li.min .in_item_content {
    opacity: 1;
  }

  .in_information_box,
  .in_news_box {
    width: 100%;
    max-width: 100%;
  }

  .in_news_box {
    margin-bottom: 60px;
  }

  .in_video_box {
    margin-bottom: 40px;
  }

  .in_video_box,
  .in_item_5 .in_case_box {
    position: relative;
    width: 100%;
    max-width: 100%;
  }

  .in_case_box ul {
    width: 100%;
  }

  .banner .slick-prev {
    left: 0%;
  }

  .banner .slick-next {
    right: 0%;
  }
}

@include media-breakpoint-down(xs) {
  #top-menu-ul .item_menu_Box li a {
    font-size: pxToEm(15);
  }

  .banner .slick-dots li {
    margin: 0 7px;
  }

  .hr_line {
    display: none;
  }

  .in_case_box .in_case_content {
    flex-wrap: wrap;
  }

  .in_case_box {
    margin: 20px auto 0 auto;

    ul {
      width: 100%;
      margin-bottom: 0;
    }
  }

  .in_doctors_box {
    position: relative;

    .in_doctors_list {
      padding: 48px 0 0 0;
      margin: auto;
      width: 91%;
    }

    .more_btn {
      position: absolute;
      top: 10%;
      right: 7.5%;
      z-index: 1;
    }
  }

  .in_doctors_box .in_doctors_list li a {
    font-size: pxToEm(16);
    padding: 10px 11px;
    height: 38px;
    line-height: 1.5;
  }

  .in_item_3 {
    .video_list .slick-slide li {
      max-width: 175px;

      @include screen(375px) {
        max-width: 159px;
      }

      @include screen(360px) {
        max-width: 154px;
      }
    }
  }

  .in_work_box .case_list_img {
    max-width: 168px;

    @include screen(375px) {
      max-width: 152x;
    }
  }

  .in_doctors_box h2 {
    width: 100%;
    height: 25px;
    background-size: contain;
    margin-bottom: 15px;
  }

  .in_item_3 h2 {
    width:100%;
    height: 26px;
    background-size: contain;
    margin-bottom: -5px;
  }

  .in_item_3 h3,
  .in_work_box h3 {
    font-size: pxToEm(15);
  }

  .in_work_box h2 {
    width: 110px;
    height: 26px;
    background-size: contain;
    margin-bottom: -5px;
  }


  .in_news_box {

    .new_btn,
    .media_btn {
      width: 85px;
      height: 37px;
      background-size: contain;
    }

    .new_btn {
      margin-right: 15px;
    }
  }

  .in_news_box {
    .new_title {
      width: 80px;
    }

    .in_new_txt {
      width: 58%;

      h2 {
        font-size: pxToEm(15);
      }

      p {
        font-size: pxToEm(13);
      }
    }
  }

  .features {
    display: block;

    h3 {
      font-size: pxToEm(17);
    }

    a {
      width: 100%;
      display: block;
      margin: 0 0 34px 0 !important;
    }

    .features_img {
      width: 230px;
      height: 230px;
      margin: 0 auto 11px auto;
    }
  }

  .in_item_1 {
    padding: 0px 0 20px;
  }

  .in_item_1 li {
    height: 0;
    padding-bottom: 45%;
  }

  .in_item_1 .in_item_content h3 {
    font-size: 1rem;
  }

  .in_item_1 .in_item_content {
    bottom: 0;
  }

  .in_item_1 .in_item_content .in_item_text {
    display: none;
  }

  .in-title {
    font-size: pxToEm(22);
    margin-bottom: 0;
    &:after {
      width: 91px;
    }
  }

  .banner .slick-next {
    right: 2%;
  }

  .banner .slick-next,
  .banner .slick-prev {
    width: 30px;
    height: 30px;
    margin: -4px 0 0 7px;
  }

  .banner .slick-prev:before {
    margin: -4px 0 0 10px;
  }

  .banner .slick-next:before {
    margin: -1px 0 0 11px;
  }

  .in_arrw {
    font-size: 1rem;
  }

  .in_arrw a:after {
    width: 19px;
    height: 19px;
  }

  .in_item_2 {
    padding-bottom: 10px;
  }

  .in_item_2 .slider-nav {
    margin: -19px auto 0;
    width: 86%;
  }

  .in_news_box .in_arrw {
    top: -16px;
  }

  .in_news_box .news_content {
    padding: 6% 0;
  }

  #top-menu-ul .item_menu_Box li:not(:last-child) {
    margin-right: 2%;
  }
  #top-menu-ul .item_menu_Box{
    width: 85%;
  }
  #top-menu-ul .item_menu_Box li a {
    width: 97px;
    height: 40px;
    line-height: 40px;
  }

  #top-menu-ul .item_menu_Box li {
    height: 57px;
  }

  #top-menu-ul .item_Menu {
    top: -2px;
  }

  #top-menu-ul .item_menu_Box li a {
    margin: 0px 10px 0 0;
  }

  .in_item_3 {}

  .in_item_4 {
    width: 93%;
    .slick-list{
      margin-bottom: 10px;
    }
  }

  // .in_item_4 ul {
  //   flex-wrap: wrap;

  //   li {
  //     width: 47%;
  //     padding-bottom: 40.0881%;
  //     display: none;

  //     &:nth-child(1),
  //     &:nth-child(2) {
  //       display: block;
  //     }
  //   }
  // }

  .in_item_4 .in_equipment_text {
    font-size: pxToEm(13);
    padding: 2px 2%;
  }

  .in_video_box .youtobe_box {
    padding-bottom: 69%;
  }

  .in_video_box li a {
    font-size: pxToEm(15);
    margin-top: 6px;
  }

  .in_item_5 {
    padding-bottom: 45px;
  }

  .in_item_5 .in_case_box .slick-dots {
    margin-top: 20px;
  }
}