.qa_item {
    margin: 0px 0 60px;
    width: 100%;
    position: relative;
    header,h2 {
        margin-bottom: 0px;
    }
}
.problem{
    display: flex;
    align-items: center;
    margin-bottom: 44px;
    h4{
      font-weight: bold;
      font-size: pxToEm(16);
      color: #000000;  
      margin-right: 5px;
    }
    select{
        width: 100%;
        max-width: 266px;
        border: solid 1px #d4d4d4;
        background-color: #ffffff;
    }
}
.qa_box {
    margin-bottom: 50px;
    .qa-title{
      font-weight: bold;
      color: #009a85;  
      font-size: pxToEm(18);
      margin-bottom: 11px;
    }
    li {
        margin-bottom: 4px;
        list-style: none;
    }
}

.qa_list {
    width: 100%;
    color: $black;
    font-size: pxToEm(18);
    line-height: 30px;
    padding: 10px 18px 8px 23px;
    margin-bottom: 0px;
    cursor: pointer;
    background: #e6eef5;
    border-radius:4px; 
    span {
        width: 95%;
        display: inline-block;
        vertical-align: middle;
        //font-weight: bold;
    }
    &:after {
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: 10px;
        height: 16px;
        background: url(../images/chevron.png) top left no-repeat;
        background-size: cover;
        position: relative;
        top: 0px;
        right: -16px;
        transition: 0.2s;
    }
    &.show:after {
        top: -3px;
        transition: 0.3s;
        transform: rotate(90deg);
    }
}

.qa_content {
    display: none;
    padding: 26px 10px 26px 23px;
    font-size: pxToEm(16);
    letter-spacing: 0.8px;
    line-height: 30px;
}

@include media-breakpoint-down(md) {
    .qa_list:after {
        right: -26px;
    }
    .casePage .qa_box {
        margin-bottom: 0;
    }

    .qa_list {
        line-height: 22px;
        padding: 9px 0px 6px 19px;
        font-size: pxToEm(15);
        span {
            width: 91%;
        }
        @include screen(640px) {
            span {
                width: 85%;
            }
        }
    }
    .qa_content {
        padding: 15px 18px 30px 18px;
        font-size: pxToEm(15);
        line-height: 27px;
    }
    .problem{
        display: block;
        h4{
            margin-bottom: 9px;
        }
        select{
            max-width: 100%;
        }
    }
}