/*----------------------通用設定------------------------*/

@import url(https://fonts.googleapis.com/earlyaccess/cwtexfangsong.css?20190222);

//選取顏色
p,
h1,
h2,
h3,
h4,
b,
strong,
span,
li,
div,
a,
img,
tr,
td,
th,
address {
  &::selection {
    color: $white;
    background: #c60a26;
  }
}

body {
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  min-height: 100%;
  line-height: 1em;
  overflow: auto;
  font-size: $txt_16;
  color: $black;
  word-break: break-word;
  letter-spacing: 0.05em;
  font-family: $font_content;
  z-index: 0;
}

//a tag
a {
  text-decoration: none;
  outline: 0;
}

a,
a:hover {
  transition: 0.4s;
}

img {
  vertical-align: middle;
  max-width: 100%;
}

//phone
a[x-apple-data-detectors] {
  color: inherit !important;
  text-decoration: none !important;
  font-size: inherit !important;
  font-family: inherit !important;
  font-weight: inherit !important;
  line-height: inherit !important;
}

a[href^="tel"] {
  color: inherit;
  /* Inherit text color of parent element. */
  text-decoration: none;
  /* Remove underline. */
  /* Additional css `propery: value;` pairs here… */
}

//border-box
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

//jqimgFill 
.jqimgFill {
  position: relative;
  overflow: hidden;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: inline-block;
  transition: opacity .4s ease-in-out, transform .4s ease-in-out;
}

.jqimgFill img,
.jqimgFill-tc img {
  //position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  display: block;
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  max-height: none;
  max-width: none;
  transition: opacity .4s ease-in-out, transform .4s ease-in-out;
}

// input,select,textarea
input,
select,
textarea {
  width: 100%;
  height: 50px;
  outline: none;
  padding: 0 10px;
  color: $black;
  background: #eeeeee;
  border: 1px solid $white;
  font-size: pxToEm(16);

  @include screen(640px) {
    font-size: pxToEm(15);
  }

  &:focus {
    transition: .4s;
    border: 1px solid #eee;
  }
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  background: #000 !important;
}

textarea {
  height: 173px;
  padding: 10px;
}

// input placeholder
::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #999;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #999;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #999;
}

:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #999;
}

//移除 input type="date"箭頭
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type='date'],
input[type='time'] {
  // -webkit-appearance: none;
}

select {
  font-family: $font_content;
  // appearance: none;
  // -moz-appearance: none;
  // -webkit-appearance: none;
  // background: url(../svg/arrw_1.svg?20181225) no-repeat scroll 96.9% center transparent;
  background-size: 3.4%;
  padding-right: 14px;
  height: 40px;
  width: 100%;
}

// select::-ms-expand {
//     display: none;
// }

.selectBox {
  background: #FFF;
}

// header fix狀態時 錨點用
body :target:before {
  display: block;
  content: "";
  height: 120px;
  margin: -120px 0 0;

  @media screen and (max-width: 960px) {
    height: 100px;
    margin: -100px 0 0;
  }
}

body .slide-down :target:before {
  display: block;
  content: "";
  height: 120px;
  margin: -120px 0 0;
}

.slick-slide {
  outline: 0;
}

.slick-next, .slick-prev{
  z-index: 5;
}
/*----------------------main_banner------------------------*/

.main_banner {
  width: 100%;
  height: 332px;
}

.container_Box {
  max-width: 1170px;
  width: 95%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.left_box {
  width: 30%;
  max-width: 300px;
  margin-right: 28px;
}

.left_nav {
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
  max-width: 284px;
}

.right_content {
  @include calc(285px);
  max-width: 830px;

  .we-content {
    border-radius: 10px;
    background-color: #ffffff;
    padding: 36px 40px;
    box-shadow: 0 0 4px 0 rgba(13, 5, 9, 0.2);
    margin-bottom: 19px;
  }

  header {
    position: relative;
    padding-bottom: 16px;
    margin-bottom: 30px;

    h1 {
      position: relative;
      width: 100%;
      font-size: pxToEm(30);
      line-height: 1.2;
      letter-spacing: 1.5px;
      text-align: left;
      color: #c60a26;
      margin-bottom: 13px;
      border-bottom: 1px solid #c60a26;
      padding-bottom: 11.5px;
      font-weight: bold;

      &::before {
        width: 30px;
        height: 21px;
        display: inline-block;
        content: "";
        background: url(~svg/wechat_icon.svg?0be820a0) top left no-repeat;
        background-size: contain;
        margin-right: 13px;
        text-align: right;
      }
      &:after{
        position: absolute;
        bottom: -6px;
        right: 0px;
        content: "";
        width: 13px;
        height: 13px;
        background: #c60a26;
        border-radius: 99px;
      }
    }

    .status {
      width: 86%;
      display: flex;
      justify-content: space-between;
      position: absolute;
      align-items: center;
      @include screen(1036px) {
        width: 82%;
      }
      @include screen(480px) {
        width: 63%;
      }
    }

    time {
      font-weight: bold;
      text-align: right;
      font-size: pxToEm(14);
      color: #333333;
      display: block;
      @include screen(480px) {
        font-size: pxToEm(12);
      }
    }

    .fb-like {
      width: 100%;
      text-align: right;
      display: block !important;
    }

    time+.fb-like {
      position: relative;
      top: -17px;
      margin-bottom: -9px;
      display: block;
    }
  }

  .case_title {
    position: relative;
    width: 100%;
    font-size: pxToEm(16);
    line-height: 1.2;
    letter-spacing: 1.5px;
    text-align: left;
    color: $black;
    border-bottom: 1px solid $blue_title;
    padding-bottom: 24px;
    margin-bottom: 13px;

    &::after {
      display: block;
      content: '';
      width: 103px;
      height: 4px;
      background: $blue_title;
      position: absolute;
      bottom: 0;
    }

    h1 {
      padding-bottom: 6px;
      margin-bottom: 0;
      border-bottom: none;

      &::after {
        display: none;
      }
    }

    h3 {
      line-height: 1.3;
    }
  }
}


/*----------------------title------------------------*/
.case_box,
.qa_item,
.from_box {
  h2 {

    font-size: pxToEm(30);
    line-height: 1.33;
    letter-spacing: 1.5px;
    color: $blue_title;
    margin-bottom: 30px;
  }
}

.title {
  position: relative;
  width: 100%;
  padding: 22px 0 22px 0px;
  margin-bottom: 12px;
  border-radius: 5px;
  background-color: #009a85;
  text-align: center;
  background-size: cover;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 99em;
    background-color: #d1dee8;
  }

  &::before {
    left: 5%;
  }

  &::after {
    right: 5%;
  }

  h2 {
    color: $white;
    font-size: pxToEm(22);
    line-height: 1;
  }
}


/*----------------------left_btn------------------------*/

.item_nav {
  font-size: pxToEm(16);
  letter-spacing: 0.9px;
  padding: 0 3px 20px 3px;

  li {
    list-style: none;
    font-weight: bold;
    margin-bottom: 10px;
  }

  >ul>li {
    margin-bottom: 0.5em;
    line-height: 1.2;

    &.active {
      >a {
        margin-bottom: 10px;
        border-radius: 3px;
        background-color: #ffffff;
      }
    }
  }

  >ul>li>a {
    position: relative;
    width: 100%;
    display: block;
    text-decoration: none;
    color: #003126;
    padding: 10px 19px;
    font-weight: bold;

    &::before {
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      background: #009a85;
      border-radius: 99em;
      margin-right: 10px;
    }

    &:hover {
      color: #c60a26;
    }

  }
}

.physician_detail_Page {
  .item_nav>ul>li>a:after {
    transition: .2s;
    display: block;
    content: '';
    position: absolute;
    top: 41%;
    right: 10px;
    width: 8.5px;
    height: 14px;
    background: url(~images/arrw_3.png) no-repeat;
    background-size: contain;
    transform: translateX(-50%) rotate(-90deg);
  }
}

.item_nav>ul>li.hit>a::after {
  top: 50%;
  right: 15px;
  transform: translateX(-50%) rotate(0deg);
}

.hit .item_btn {
  &.open {
    display: block;
  }

  li.active a {
    color: $white;
    background-color: #e6d4bd;
    border-radius: 3px;
  }
}

.item_btn {
  display: none;
  font-size: pxToEm(15);

  a {
    display: block;
    color: #baa98d;
    text-decoration: none;
    padding: 11px 15px 11px 31px;
    width: 94%;
    margin: auto;

    span {
      display: inline-block;
      vertical-align: top;
    }

    &:hover {
      color: #d1a369;
    }
  }
}


/*----------------------麵包屑------------------------*/

.path {
  width: 100%;
  text-align: right;
  font-size: pxToEm(14);
  color: $path;
  margin-top: 25px;
  margin-bottom: 27px;

  a {
    color: $path;
    text-decoration: none;

    &:after {
      content: ' / ';
    }

    &:hover {
      color: #009a85;
    }
  }
}


/*----------------------大區塊------------------------*/

#Wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  min-height: 100%;
  min-width: 320px !important;
  overflow: hidden;
  z-index: 2;
}

main[role="main"] {
  display: block;
  width: 100%;
  @extend %cf;
  position: relative;
  margin-top: 100px;
  padding-bottom: 89px;
  background: #d1dee8;
  background-size: contain;
}

.ariter_bg {
  width: 100%;

}

section {
  position: relative;
  @extend %cf;
}

article {
  @include secction_width(1200px, 90%);
}


/*----------------------每個選單Highlight------------------------*/

// .aboutPage nav ul li:nth-child(1) a,
// .clientsPage nav ul li:nth-child(2) a,
// .productPage nav ul li:nth-child(3) a,
// .product_detailPage nav ul li:nth-child(3) a,
// .manufacturePage nav ul li:nth-child(4) a,
// .manufacture_detailPage nav ul li:nth-child(4) a,
// .contactPage nav ul li:nth-child(6) a {
//     color: $green;
//     &:after {
//         width: 100%;
//     }
// }

/*----------------------內頁單元標題------------------------*/

.title_box {
  margin: 0 auto 20px auto;
  text-align: center;
  font-family: "DINPro-Regular";
  font-size: pxToEm(16);
  line-height: 24px;
  color: #444;
}


/*----------------------編輯器------------------------*/

.editor_Content {
  position: relative;
  width: 100%;
  margin: 0 auto;
  background: $white;
  padding: 79px 0;
  // @include border_show(2.5em,2.5em,0,0);
}

.editor_Box {
  @include secction_width(100%, 1000px);
  margin: auto;
  line-height: 30px;
  font-size: pxToEm(16);

  ul,
  ol {
    padding-left: 40px;
  }

  img {
    max-width: 100%;
    height: auto !important;

    @media screen and (max-width: 768px) {}
  }

  iframe {
    max-width: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: inherit;
  }
}

.mo_use {
  display: none;
}

/*----------------------300x250廣告------------------------*/
.advertising {
  max-width: 300px;
  margin-top: 19px;

  a {
    display: block;
    width: 100%;
    height: 100%;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  img {
    width: 100%;
  }

  @include media-breakpoint-down(md) {
    display: none;
  }
}

/*----------------------back------------------------*/

.back_btn {
  width: 100%;
  text-align: center;
  margin-top: 40px;
  font-size: pxToEm(16);
  letter-spacing: 0;
  font-weight: 600;

  a {
    margin: auto;
    height: 40px;
    display: inline-block;
    color: #79be62;
    background-color: #eeeeee;
    text-decoration: none;
    padding: 0 45px;
    line-height: 40px;

    &:hover {
      opacity: 0.7;
    }
  }
}

.slick-list{
  margin-bottom: 40px;
}
.slick-dots{
  position: relative;
  margin: 0;
  li{
      width: auto;
      height: auto !important;
      margin: 0 6px 0 0;
      &.slick-active button:before,&.slick-active div:before{
          background-color: #cb102a; 
      }
  }
  li button,li div{
      display:block; 
      width: 42px;
      height: 6px;

      &:before{
        content:'';
         display:block; 
          transition: .4s;
          width: 100%;
          height: 6px;
          background-color: #005846; 
          border-radius:0;
      }
      &:hover::before{
          background-color: #cb102a; 
      }
  }
  
}
/*----------------------上一則 下一則------------------------*/

.ud_btn {
  width: 100%;
  margin-top: 20px;
}

.ud_l,
.ud_r {
  width: 100%;
  display: block;
  font-size: pxToEm(16);
  line-height: 2.25;
  letter-spacing: 0.8px;
  text-align: left;
  text-decoration: none;
  color: #009a85;

  &:hover {
    color: #c60a26;
  }
}


/*----------------------GO TOP------------------------*/

// .top_btn {
//     transition: 0.5s;
//     opacity: 0;
//     position: fixed;
//     bottom: 3%;
//     right: 43px;
//     z-index: 8;
//     font-size: pxToEm(12);
//     z-index: 9;
//     a {
//         display: block;
//         color: $green;
//         position: relative;
//         &:hover .line {
//             height: 85px;
//         }
//     }
//     span {
//         position: absolute;
//         bottom: 0;
//         display: block;
//         -webkit-writing-mode: vertical-rl;
//         writing-mode: vertical-rl;
//         transform: rotate(-180deg);
//     }
//     .line {
//         transition: .4s;
//         position: relative;
//         width: 1px;
//         height: 64px;
//         background: $green;
//         display: block;
//         left: 4px;
//         bottom: 0;
//         &:before {
//             display: block;
//             position: absolute;
//             top: 4px;
//             left: -13px;
//             content: "";
//             width: 16px;
//             height: 1px;
//             background: #007b60;
//             transform: rotate(134deg);
//         }
//     }
//     &.active {
//         transition: 0s;
//         opacity: 1;
//     }
//     &.fix {
//         position: absolute;
//         transition: 0s;
//         bottom: 112.2%;
//         @include screen(768px) {
//             bottom: 105.2%;
//         }
//     }
// }

/*----------------------頁數------------------------*/

.page {
  font-size: pxToEm(14);
  clear: both;
  width: 90%;
  max-width: 530px;
  text-align: center;
  margin: 0 auto;
  @include border();

  dt,
  dd {
    vertical-align: middle;
    display: inline-block;

    a {
      display: block;
      width: 23px;
      height: 23px;
      line-height: 22px;
      text-decoration: none;
      color: #c60a26;

      &:hover {
        color: $white;
        background: #c60a26;
      }
    }
  }

  dd:not(:last-child) {
    margin-right: 4px;
  }

  dd.active a {
    background: none;
    padding: 0;
    font-weight: bold;
    color: $white;
    background: #c60a26;
  }

  dt.rtbn a,
  dt.ltbn a {
    transition: 0.4s;
  }

  .nopage {
    opacity: 0.2;
  }
}

//版本使用
@include screen(768px) {
  .mo_use {
    display: block;
  }

  .pc_use {
    display: none;
  }

  .top_btn {
    right: 20px;
  }

  right: 20px;
}

@include media-breakpoint-down(lg) {}

@include media-breakpoint-down(md) {
  .container_Box {
    width: 100%;
  }

  .path {
    width: 89%;
    margin: 20px auto 14px;
    order: 2;
  }

  .left_nav {
    top: -8px;
    order: 1;

    &:after {
      transition: .3s;
      position: absolute;
      top: 16px;
      right: 15px;
      transform: rotate(-90deg);
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      line-height: 1;
      display: block;
      text-align: center;
      font-weight: 900;
      font-family: Font Awesome\ 5 Free;
      content: "\f107";
      color: #fff;
      z-index: 5;
      font-size: pxToEm(22);
    }

    &.active::after {
      transform: rotate(0deg);
    }
  }

  .container_Box {
    flex-wrap: wrap;
  }

  .left_box,
  .right_content {
    width: 90%;
    max-width: 100%;
    margin: auto;
  }

  .right_content {
    order: 3;

    header {
      padding-bottom: 5px;
      margin-bottom: 10px;
    }

    header .fb-like {
      margin-bottom: 10px;
      display: -webkit-inline-box;
    }
  }

  .main_banner {
    margin-top: 70px;
  }

  .ariter_bg {
    margin-top: 120px;
  }

  .main_banner+.ariter_bg {
    margin-top: 0;
  }

  main[role=main] {
    display: block;
    margin-top: 0;
    padding-bottom: 40px;
  }

  .main_banner {
    height: 200px;
  }

  .left_nav {
    position: relative;

    .title.active+.item_nav {
      transition: .4s;
      opacity: 1;
      z-index: 1;
      top: 3.4em;
    }
  }

  .item_nav {
    width: 100%;
    transition: .2s;
    position: absolute;
    opacity: 0;
    z-index: -1;
    top: -1em;
    background: $white;

    padding: 7px 3px 3px;
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.12);
    @include border_show(0, 0, 5px, 5px);
  }

  .left_nav {
    overflow: initial;
    max-width: 90%;
    margin: auto;
  }

  .title {
    padding: 14px 20px;
    z-index: 2;
    margin-bottom: 0;
    @include border_show(5px, 5px, 5px, 5px);
    &::after{
      display: none;
    }
    h2 {
      margin-bottom: 5px;
    }
  }

  .right_content {
    header {
      h1 {
        font-size: pxToEm(24);
        background-size: 29px 26px;
        font-weight: bold;
      }
    }
  }
}

.g-recaptcha {
  opacity: 0;
}

@include screen(768px) {
  main[role=main] {
    padding-bottom: 40px;
  }

  .page dt,
  .page dd {
    width: 9%;
    margin: 0px 1%;
    font-size: pxToEm(16);

    a {
      height: 30px;
      line-height: 30px;
      width: 30px;
    }
  }

  .page dd.active a {
    font-size: pxToEm(18);
  }

  .editor_Box {
    font-size: pxToEm(15);
  }
  .right_content .we-content{
    padding: 36px 25px;
  }
}



@include media-breakpoint-down(sm) {
  .slick-dots li button {
    width: 28px;
    height: 2px;
  }
  .slick-dots li button:before,.slick-dots li div:before{
    height: 4px;
  }
  .right_content .case_title h3 {
    font-size: pxToEm(15);
  }

  .path {
    font-size: pxToEm(13);
  }

  .editor_Content {
    padding: 40px 8px;
  }

  .main_banner {
    height: 60px;

    @include screen(375px) {
      height: 80px;
    }

    h2 {
      font-size: pxToEm(22);
    }

    h1 {
      font-size: pxToEm(16);
    }
  }

  .case_box h2,
  .from_box h2,
  .qa_item h2 {
    font-size: pxToEm(22);
  }
}