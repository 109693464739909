.ne1{
  text-align: center;
  min-width: 65px;
  display: inline-block;
  background: #009a85;
  color: #fff;
  font-size: 12px;
  height: 20px;
  line-height: 18px;
  border-radius: 3px;
  padding: 0 1%;
}
.ne2{
  text-align: center;
  min-width: 65px;
  display: inline-block;
  background: #c60a26;
  color: #fff;
  font-size: 12px;
  height: 20px;
  line-height: 18px;
  border-radius: 3px;
  padding: 0 1%;
}
.ne3{
  text-align: center;
  min-width: 65px;
  display: inline-block;
  background: #fe8e3f;
  color: #fff;
  font-size: 12px;
  height: 20px;
  line-height: 18px;
  border-radius: 3px;
  padding: 0 1%;
}
.ne4{
  text-align: center;
  min-width: 65px;
  display: inline-block;
  background: #4698d8;
  color: #fff;
  font-size: 12px;
  height: 20px;
  line-height: 18px;
  border-radius: 3px;
  padding: 0 1%;
}
@include screen(768px) {}