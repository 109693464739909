aside {
  transition: .4s;
  opacity: 0;
  position: fixed;
  right: 1%;
  bottom: 2%;
  //width: 45px;
  z-index: 9;

  li {
    list-style: none;
    margin-bottom: 5px;
  }

  &.active {
    opacity: 1;
  }

  .top_btn {
    display: block;
    width: 35px;
    height: 35px;
    text-indent: -10000px;
    background: url(../images/top.png) top left no-repeat;
    background-size: cover;
  }
}



//------------------------- 常駐按鈕 -------------------------//
.permanent {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0px 1px 4px 0 rgba(13, 5, 9, 0.4);
  background-color: #eeeeee;
  z-index: 8;
  padding: 2% 2% 0 2%;
  margin-bottom: 0;

  img {
    width: 100%;
    display: block;
    margin: 0 auto 7px auto;
  }

  ul {
    display: flex;
    justify-content: center;
  }

  li {
    list-style: none;
    margin: 0 2%;

    @include screen(360px) {
      margin: 0 1%;
    }
  }

  a {
    color: $white;
    font-size: pxToEm(14);
    text-decoration: none;
  }
}

// footer
footer {
  width: 100%;
  background: url(~images/footer_bg.png) #fff top left no-repeat;
  padding-top: 39px;
  font-size: pxToEm(14);
  line-height: 1.29;
  letter-spacing: 0.7px;
  color: #535353;

  ul {
    display: flex;
  }

  li {
    list-style: none;
    width: 33.3333%;

    &:not(:last-child) {
      border-right: 1px solid #d2d2d2;
      margin-right: 22px;
      padding-right: 22px;
    }
  }

  h2 {
    font-size: pxToEm(17);
    font-weight: bold;
    margin-bottom: 11px;
  }

  a {
    display: block;
    margin-bottom: 11px;

  }

  .f_map {
    color: #535353;
    text-decoration: none;
  }

  .f_tel {
    color: #f9763c;
  }
}

.footer_box {
  width: 90%;
  margin: auto;
}

.footer-content {
  display: flex;
  margin-bottom: 17px;
}

.f_logo_add {
  margin-right: 5.1%;
}

.f_logo {
  transition: .4s;
  text-indent: -10000px;
  display: block;
  width: 271px;
  height: 44px;
  background: url(~images/logo.png) top left no-repeat;
  background-size: cover;
  margin-bottom: 25px;
  &:hover{
    opacity: .7;
  }
}

.f_link {
  display: flex;

  img {
    height: 33px;
  }

  a:hover {
    opacity: .7;
  }

  a:not(:last-child) {
    margin-right: 10px;
  }
}

.copyright {
  text-align: center;
  font-size: pxToEm(13.9);
  color: #ffffff;
  background-color: #c60a26;
  padding: 14px 0;
}

.f_prompt {
  margin-bottom: 12px;
}

@include media-breakpoint-down(lg) {

  .footer-content {
    display: block;
  }

  footer ul {
    display: block;

  }

  footer li {
    width: 100%;
    text-align: center;

    &:not(:last-child) {
      margin-right: 0;
      padding-right: 0;
      padding-bottom: 2%;
      margin-bottom: 5%;
      border-right: none;
      border-bottom: 1px solid #d2d2d2;
    }
  }

  .f_logo_add {
    margin-right: 0;
    margin-bottom: 21px;
    margin: auto;
    text-align: center;
  }

  .f_logo {
    transition: .4s;
    margin: 0 auto 25px auto;

  }

  .f_link {
    justify-content: center;
    margin-bottom: 20px;
  }

  aside {
    right: -1px;
    bottom: 12%;
  }
}

@include screen(768px) {
  .permanent {
    display: block;
  }
  footer{
    margin-bottom: 11%;
  }
}

@include media-breakpoint-down(sm) {
  footer{
    margin-bottom: 13%;
  }
}