.msg-box{
  th,td{
    font-weight: bold;
    vertical-align: middle;
    padding: 10px 21px;
    border-bottom: 4px solid #fff;
    line-height: 1.6;
  }
  table{
    width: 100%;
  }
  .table-btn{
    position: relative;
    cursor: pointer;
    &:after {
      position: absolute;
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: 10px;
      height: 16px;
      background: url(../images/chevron.png) top left no-repeat;
      background-size: cover;
      top: -3px;
      right: 0;
      transition: 0.2s;
      transform: rotate(90deg);

  }
  &.active:after {
    top: -3px;
    transition: 0.3s;
    transform: rotate(-90deg);
  }
  }
  .table-box{
    display: none;
  }
  th{
    width: 15%;
    text-align: center;
    background-color: #e6eef5;
  }
  td{
    width: 82%;
  }
  .msg-list{
    margin-bottom: 40px;
  }
  .msitem{
    color: #009a85;
  }
}
.message_btn{
  display: block;
  text-align: center;
  width: 150px;
  height: 40px;
  border-radius: 5px;
  background-color: #cb102a;
  font-size: pxToEm(18);
  line-height: 40px;
  color: #fff;
  margin-bottom: 75px;
  text-decoration: none;
  &:hover{
    opacity: .7;
    color: #fff;
    text-decoration: none;
  }
}
@include media-breakpoint-down(md) {
  .msg-box th{
    width: 20%;
  }
  .msg-box td, .msg-box th{
    padding: 10px 12px;
  }
  .msg-box .table-btn:after{
    top:10px;
  }
}