.contact_Page{
    .from_box{
        h2,.from_content h3{
            display: none;
        }
    }

}
//聯絡我們表格
.from_box {
    width: 100%;
    header,h2 {
        margin-bottom: 0px;
    }
}
.from_content{
    background-color: #fff;
    padding: 60px 50px 40px 50px;
    h3{
        width: 100%;
        position: relative;
        font-size: pxToEm(18);
        color: $black;
        margin-bottom: 30px;
        font-weight: bold;
        line-height: 1.3;
    }
}
.form-group {
    @extend %cf;
    width: 100%;
    margin: 0;
    margin-bottom: 5px;
}

.control-label { 
    float: left;
    width: 115px;
    text-align: right;
    font-weight: bold;
    font-size: pxToEm(16);
    padding-top: 7px;
    margin-bottom: 0;
    .red {
        color: #bf0d0d !important;
    }
    b {
        font-size: 16px;
        margin-right: 3px;
        color: #bf0d0d;
    }
}
.check_errors, .list-unstyled li {
    list-style: none;
    font-size: .875rem;
    letter-spacing: .7px;
    color: #bf0d0d;
    margin: 5px 0 7px 5px;
}
.form-control:focus{
    border-color: #bf0d0d;
    box-shadow: none;
}
.has-danger .col.select_box:after{
    top: 28%;
}
.col {
    float: left;
    width: calc(100% - 115px);
    margin-bottom: 0px;
    &:after {
        clear: both;
    }
    &.select_box{
        position: relative;
        width: 296px;
        &:after{
            position: absolute;
            right: 21px;
            top: 49%;
            @include triangle_specially(tran_bottom,5px,4.5px,#c8c8c8);
            display: none;
        }
    }
    textarea,
    select,
    input {
        margin-left: 5px;
        display: block;
        width: 100%;
        height: 36px;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.42857143;
        color: #555;
        background-color: #fff;
        background-image: none;
        border: 1px solid #d8d8d8;
        border-radius: 0;
    }
    textarea {
        width: 100%;
        height: 162px;
    }
    .radio input {
        width: 18px;
        height: 18px;
        border: none;
        display: inline-block;
        box-shadow: none;
        vertical-align: sub;
        margin-right: 3px;
    }
    #inputCode {
        display: inline;
        margin-right: 5px;

        // width: 200px;
    }
    .code-pic {
        display: inline;
        margin-right: 5px;
    }
    .change {
        color: #960000;
        font-size: 14px;
        cursor: pointer;
        transition: all 0.5s;
        text-decoration: none;
    }
}

.form-button {
    display: block;
    margin: 26px auto 20px auto;
    width: 100%;
    text-align: center;
    .btn:first-of-type {
        background-color: none;
    }
    .btn {
        font-family: Arial, Verdana, 微軟正黑體;
        display: inline-block;
        margin: 20px 5px 5px 5px;
        width: 150px;
        height: 40px;
        line-height: 40px;
        color: #fff;
        font-size: 20px;
        border: none;
        background: none;
        border-radius: 0;
        transition: all 0.5s;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        cursor: pointer;
        &:hover {
            opacity: 0.7;
        }
        &:nth-child(1) {
            background: #cb102a;
        }
        &:nth-child(2) {
            background: #005846;
        }
    }
}

.radio {
    margin-top: 10px;
}

@include media-breakpoint-down(sm) {
    .from_box {
        width: 100%;
    }
    .col.select_box{
        width: 100%;
    }
    .control-label,
    .col {
        float: none;
        width: 100%;
        display: block;
        padding: 0;
    }
    .control-label {
        text-align: left;
        margin-bottom: 9px;
    }
    .form-group{
        margin-bottom: 20px;
    }
    .from_content{
        padding: 40px 15px 40px;
    }
    .col input,
    .col select,
    .col textarea {
        width: 100%;
        margin-left: 0;
    }
    .form-button .btn{
        width: 45%;
    }
}