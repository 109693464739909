.video_box {
    width: 100%;
    margin-bottom: 31px;
}

.video_content {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56%;
    iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
}
.video_Page .right_content header .status{
    display: block;
    text-align: right;
    width: 84%;
    padding-top: 1px;
}

.video_Page .col-6 {
    margin-bottom: 34px;
    a{
        width: 100%;
        max-width: 350px;
        display: block;
        margin: auto;
        text-decoration: none;
        &:hover{
            opacity: .7;
        }
    }
}
.list_photo {
    position: relative;
    overflow: hidden;
    width: 36%;
    height: 0;
    max-width: 263px;
    border-radius: 3px;
    padding-bottom: 23.468%;
    .list_img {
        transition: .6s;
        transform: scale(1);
        height: 0;
        padding-bottom: 55.715%;
    }
    i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: block;
        width: 36px;
        height: 25px;
        background: url(../images/youtobe_icon.png) top left no-repeat;
        z-index: 2;
    }
}

.ynsa_share_txt {
    width: 100%;
    font-size: pxToEm(18);
    color: $black;
    time {
        display: block;
        margin-bottom: 10px;
        color: $blue;
        font-size: pxToEm(16);
    }
    h2 {
        transition: .4s;
        letter-spacing: 0.5px;
        @include clamp(1.33, 2);
        color: #aa9371;
        font-size: pxToEm(16);
    }

}

@include media-breakpoint-down(md) {
    .ynsa_share_txt {
        font-size: pxToEm(16);
        time {
            font-size: pxToEm(15);
        }
    }

}

@include media-breakpoint-down(md) {
    .video_list .col-6 {
        padding-right: 5px;
        padding-left: 5px;
        margin-bottom: 12px;
    }
}