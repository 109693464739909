/*----------------------header------------------------*/

.header_show {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    background: $white;
    z-index: 10;

    .header_box {
        position: relative;
        width: 100%;

    }

    .bottom-header {
        display: flex;
        width: 100%;
        box-shadow: 0px 1px 4px 0 rgba(13, 5, 9, 0.4);
        background: url(~images/logo_bg.png) #f7f7f7 top left no-repeat;
        padding-left: 4.5%;
        &.fix {
            position: fixed;
            top: 0;
            left: 0;
            height: 67px;

            .language_btn {
                display: none;
            }
        }
    }

    .nav_item {
        width: 100%;
        height: 58px;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        position: relative;
        // &.fix {
        //     position: fixed;
        //     top: 0;
        //     left: 0;
        //     flex-wrap: wrap;
        //     align-items: flex-start;
        //     height: 67px;

        //     .language_btn {
        //         display: none;
        //     }

        //     &:before {
        //         content: '';
        //         width: 100%;
        //         height: 12px;
        //         display: block;
        //         // background: url(~images/header_bg.jpg) left bottom no-repeat;
        //         margin-bottom: 17px;
        //     }
        // }
    }

}

.top_header {
    width: 100%;
    height: 42px;
    background: #c60a26;
    position: relative;
    padding: 0 4%;
    background-size: cover;
}

.logo {
    width: 288px;
    height: 64px;
    background: url(../images/logo.png) center left no-repeat;
    text-indent: -10000px;
    background-size: contain;
    transition: .4s;

    a {
        width: 100%;
        height: 100%;
        display: block;

    }

    &:hover {
        opacity: .7;
    }
}

/*----------------------nav------------------------*/
.nav_box {
    position: relative;
    top: -5px;
}

nav {
    li {
        position: relative;
        list-style: none;
        font-size: pxToEm(16);
        line-height: 18px;
    }

    >ul {
        display: flex;
        justify-content: center;

        >li {
            a {
                color: $nav;
                text-decoration: none;
            }

            &:hover {
                >a {
                    color: #009a7a;
                    font-weight: bold;
                    text-decoration: none;

                    span {
                       
                    }
                }
            }
            &.btn-information >a span:last-child::after{
                display: none;
            }
            >a {
                span {
                    position: relative;
                    @include border(5px);
                    display: block;
                    padding: 6px 23px;
                    @include screen(1366px) {
                        padding: 6px 14px;
                    }
                    &::after{
                        content: "";
                        display: block;
                        width: 1px;
                        height: 20px;
                        background: #c8c8c8;
                        position: absolute;
                        right: 0;
                        top: 6px;
                    }

                }

                &:after {
                    display: block;
                    margin: 5px auto 0 auto;
                    content: "";
                    @include triangle_specially(tran_bottom, 5px, 4.5px, #c8c8c8);
                }
            }

        }
    }
}

//次要選項
.sub_menu {
    position: absolute;
    display: none;
    background: #fff;
    position: absolute;
    width: 134px;
    top: 54px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 5px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.13);
    overflow: hidden;

    a {
        color: $black;
        text-decoration: none;
        display: block;
        padding: 10px 10px;

        &:hover {
            color: $white;
            background: #ca1130;
        }
    }
}

.dream_btn,
.physician_btn,
.language_btn {
    a:after {
        display: none;
    }
}

.physician_btn {
    margin-right: 0 !important;
}

/*----------------------language------------------------*/
.nav_box .language_btn {
    display: none;
}

.minor_item .language_btn {
    a:first-child {
        margin-right: 11px;
    }

    a:hover {
        opacity: 0.5;
    }
}

.language_btn {
    font-size: pxToEm(16);

    a {
        padding: 0 10px;
        height: 26px;
        line-height: 26px;
        display: inline-block;
        text-align: center;
        border-radius: 99em;
        border: solid 1px #e92b47;
        color: $white;
        text-decoration: none;

        &:hover {
            opacity: 0.5;
        }

    }
}

/*----------------------minor_item------------------------*/
.minor_item {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    color: #fff;
    a:not(:last-child) {
        margin-right: 10px;
    }

    a:hover {
        opacity: .7;
    }
}

.tel_btn {
    text-indent: -10000px;
    display: block;
}



.h-tel{
    font-size: pxToEm(14);
    color: #fff !important;
    margin-left: 10px;
   
}
.progress-btn{
    background-color: #009a85;
    padding: 12px 18px;
    position: relative;
    color: #fff;
    margin-left: 5px;
    margin-right: 0 !important;
    img{
        width:30px;
        margin-right: 8px;
    }
    &:hover{
        opacity: 1 !important;
        color: #fff;
        text-decoration: none;
        background: #3fc2b0;
    }
}
.reserve_btn {
    background-color: #ff6d07;
    padding: 12px 18px;
    position: relative;
    color: #fff;
    img{
        width:17px;
        margin-right: 8px;
    }
    &:hover{
        opacity: 1 !important;
        color: #fff;
        text-decoration: none;
        background: #ff9043;
    }
}
.community_btn {
    width: 26px;
    display: block;

    .icon path {
        transition: .4s;
    }

    &:hover {
        // .icon path{
        //     fill:#bde4fc !important;
        // }
    }
}

/*----------------------nav icon------------------------*/

.menu-wrapper {
    display: none;
}

$bar-width: 31px;
$bar-height: 3px;
$bar-spacing: 10px;

.menu-wrapper {
    display: none;
    position: absolute;
    top: -15px;
    right: 4%;
    bottom: 0;
    margin: auto;
    width: $bar-width;
    height: $bar-height + $bar-spacing*2;
    cursor: pointer;
    z-index: 10;
}

.hamburger-menu,
.hamburger-menu:after,
.hamburger-menu:before {
    width: $bar-width;
    height: $bar-height;
    @include border();
}

.hamburger-menu {
    position: relative;
    transform: translateY($bar-spacing);
    background: #a0a0a0;
    transition: all 0ms 300ms;
    @include border(99px);

    &.animate {
        background: rgba(255, 255, 255, 0);
    }

    +span {
        position: absolute;
        top: 29px;
        left: -5px;
        color: #a0a0a0;
        font-size: pxToEm(12);
        width: 40px;
        display: block;
    }
}

.hamburger-menu:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: $bar-spacing;
    background: #a0a0a0;
    transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
    @include border(99px);
}

.hamburger-menu:after {
    content: "";
    position: absolute;
    left: 0;
    top: $bar-spacing;
    background: #a0a0a0;
    transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
    @include border(99px);
}

.hamburger-menu.animate:after {
    top: 0;
    transform: rotate(45deg);
    transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
    ;
    @include border(99px);
}

.hamburger-menu.animate:before {
    bottom: 0;
    transform: rotate(-45deg);
    transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
    ;
    @include border(99px);
}

.hamburger-menu.animate {
    +span {
        display: none;
    }

    &:before,
    &:after {
        background: #009a85;
    }
}

//------------------------- Menu icon -------------------------//
.menu-icon {
    display: none;
    top: 45%;
    right: -0.7em;
    z-index: 100;
    width: 45px;
    height: 39px;
    margin-top: 0;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    cursor: pointer;
    position: absolute;

    @media (max-width: $breakpoint-tablet) {
        display: block;
    }
}

//------------------------- Mobie Menu icon -------------------------//
.menu-icon-block {
    display: none;
    position: relative;
    height: 100%;
    margin: auto;

    i {
        position: absolute;
        display: block;
        margin: auto;
        width: 72%;
        height: 2px;
        background-color: $white;
        transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        -webkit-border-radius: 99em;
        -moz-border-radius: 99em;
        border-radius: 99em;

        &.i1 {
            top: 0%;
        }

        &.i2 {
            top: 25%;
        }

        &.i3 {
            top: 51%;
        }
    }
}

.closeBtn {
    display: none;
}


@include screen(1187px) {
    nav>ul>li:not(:last-child) {
        margin-right: 10px;
    }

    nav li {
        font-size: pxToEm(15);
    }

    nav>ul>li>a span {
        padding: 6px 6px;
    }

}

@include media-breakpoint-down(lg) {}

@include media-breakpoint-down(md) {

    .minor_item {
        display: none;
    }

    .top_header {
        display: none;
        height: 70px;
    }
    .header_show .bottom-header.fix{
        height: 70px;
    }
    .header_show .bottom-header{
        height: 70px;
        background: url(../images/logo_bg_m.png) #f7f7f7 0 0 no-repeat;
        border-bottom: 9px solid #cb102a;
    }
    .logo {
        height: 43px;
        top: 12px;
        position: relative;
    }

    .header_show .logo {
        width: 229px;
        padding-bottom: 0;

    }

    .menu-wrapper {
        display: block;
    }

    .header_show {
        position: fixed;

        .header_box {
            height: 60px;
        }

    }

    .header_show .nav_item {
        position: fixed;
        top: 0;
        left: 0;
        display: none;
        width: 100%;
        height: 100%;
        background: rgba(27, 27, 27, 0.62);
        z-index: 9;

        &.fix {
            height: auto;

            .language_btn {
                display: flex;
            }

            &:before {
                display: none;
            }
        }
    }

    .nav_box .language_btn {
        display: flex;
    }

    nav li {
        font-size: pxToEm(17);
    }
    nav>ul>li>a span:after{
        display: none;
    }
    nav>ul {
        width: 100%;
        display: block;
        background: #d1dee8;
        padding: 4em 0 3em 0;
        border-top: solid 1px #009a85;
        overflow-y: scroll;
        height: 102vh;

        @include screen(360px) {
            height: 100vh;
        }

        >li {
            width: 90%;
            border-bottom: solid 1px #009a85;
            margin: auto !important;

            a {
                padding: 8px;
                width: 100%;
                display: block;

                &:after {
                    display: none;
                }

                &:before {
                    width: 10px;
                    height: 10px;
                    position: relative;
                    top: -2px;
                    display: inline-block;
                    margin-right: 9px;
                    content: '';
                    background: #009a85;
                    background-size: contain;
                    border-radius: 99em;
                }

                span {
                    display: inline-block;
                    background: #fff0;
                }
            }

            &:hover>a {
                background: #80b7d6;

                &:before {
                    background: url(~images/arrw_2.png) top left no-repeat;
                    background-size: contain;
                    transform: rotate(90deg);
                    top: 3px;
                    left: 2px;
                    width: 13px;
                    height: 12px;
                }

                span {
                    background: transparent;

                }
            }

        }
    }

    nav>ul>li:not(:last-child) {
        margin-right: 0;
    }

    .physician_btn {
        margin: auto !important;
    }

    .online_btn a,
    .faq_btn a {
        background: none;
        color: #313131;
        padding: 15px;
    }

    .sub_menu {

        position: relative;
        width: 100%;
        top: 0;
        left: 0;
        transform: translateX(0);
        border-radius: 0;
        padding: 10px 0 22px 0;
        background: none;
        box-shadow: none;

        li a:before {
            display: none;
        }

        a {
            padding: 10px 5px 10px 52px;
        }
    }

    .closeBtn {
        display: block;
        border: none;

        a {
            font-size: 20px;
            background: #c60a26;
            text-align: center;
            padding: 0;
            padding: 5px 0 13px;
            width: 89%;
            margin: 37px auto 0 auto;
            color: #fff;

            &::before {
                width: 24px;
                height: 24px;
                background: url(../images/close_icon.png) top left no-repeat;
                background-size: cover;
                border-color: none;
                border-color: transparent;
                position: relative;
                top: 4px;
            }
        }
    }

    .language_btn {
        position: static;
        display: flex;
        justify-content: center;
        width: 90%;
        border: none;
        margin-top: 22px !important;

        a:before {
            display: none;
        }

        a {
            width: 39%;
            height: 40px;
            line-height: 40px;
            margin: 0 10px;
            background: #009a85;
            border: none;
        }
    }
}