.physician_list{
    margin-bottom: 39px;
    .col-lg-12{
        margin-bottom: 50px;
    }
    a{
        width: 100%;
        display: flex;
        justify-content: space-between;
        text-decoration: none;
        &:hover{
            opacity: .7;
        }
    }
    .physician_img{
        width: 36%;
        max-width: 263px;
        border-radius: 3px;
        overflow: hidden;
        height: 0;
        padding-bottom: 23.468%;
    }
    .physician_txt{
        width: 60%;
        .status{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 11px;
        }
        time{
            font-weight: 600;
            display: block;
            font-size: .875rem;
            color: #333;
        }
        h2{
            font-size: pxToEm(18);
            color: #c60a26;
            margin-bottom: 9px;
            line-height: 1.4;
            font-weight: bold;
        }
        p{
            font-size: pxToEm(14);
            color: $black;  
            @include clamp(1.4,2);
            margin-bottom: 24px;
        }
        .more_btn{
            display: block;
            text-align: center;
            font-size: pxToEm(14); 
            color: $white;
            line-height: 30px;
            width: 97px;
            height: 30px;
            border-radius: 3px;
            background-color: #aa9371;
        }
    }
}


@include media-breakpoint-down(md) {
    .physician_list a{
        flex-wrap: wrap;
        .physician_img{
            width: 100%;
            margin-bottom: 10px;
        }
        .physician_txt{
            width: 100%;
            time{
                font-size: pxToEm(13);
            }
            h2{
                font-size: pxToEm(15);
                margin-bottom: 5px;
            }
            p{
                font-size: pxToEm(14);
                margin-bottom: 15px;
            }
            .more_btn{
                height: 27px;
                line-height: 27px;
                margin: auto;
            }
        }
    }
    .physician_list .physician_img{
        max-width: 100%;
        padding-bottom: 67%;
        margin-bottom: 10px;
    }
}

