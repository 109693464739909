@import url(https://fonts.googleapis.com/earlyaccess/cwtexfangsong.css);@import url(https://fonts.googleapis.com/earlyaccess/cwtexfangsong.css?20190222);@charset "UTF-8";
/******************************************/
/*		bootstrap-okadmin
/******************************************/
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::-webkit-input-placeholder {
    color: #868e96;
    opacity: 1; }
  .form-control::-moz-placeholder {
    color: #868e96;
    opacity: 1; }
  .form-control:-ms-input-placeholder {
    color: #868e96;
    opacity: 1; }
  .form-control::-ms-input-placeholder {
    color: #868e96;
    opacity: 1; }
  .form-control::placeholder {
    color: #868e96;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px); }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

select.form-control-sm:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px); }

.form-control-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control-lg:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px); }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #868e96; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  width: 250px;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #28a745;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #71dd8a; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
          box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }
  .was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  width: 250px;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #dc3545;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #efa2a9; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
          box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
          -ms-flex-pack: center;
              justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
          -ms-flex: 0 0 auto;
              flex: 0 0 auto;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-flow: row wrap;
          -ms-flex-flow: row wrap;
              flex-flow: row wrap;
      -webkit-box-align: center;
      -webkit-align-items: center;
          -ms-flex-align: center;
              align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group {
      width: auto; }
    .form-inline .form-check {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
          -ms-flex-pack: center;
              justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      -webkit-box-align: center;
      -webkit-align-items: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
          -ms-flex-pack: center;
              justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

/* > reset */
*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

a,
area,
button,
[role="button"],
input:not([type="range"]),
label,
select,
summary,
textarea {
  -ms-touch-action: manipulation;
      touch-action: manipulation; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #868e96;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  -webkit-flex-basis: 0;
      -ms-flex-preferred-size: 0;
          flex-basis: 0;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  max-width: 100%; }

.col-auto {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 8.33333%;
      -ms-flex: 0 0 8.33333%;
          flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 16.66667%;
      -ms-flex: 0 0 16.66667%;
          flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 25%;
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 33.33333%;
      -ms-flex: 0 0 33.33333%;
          flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 41.66667%;
      -ms-flex: 0 0 41.66667%;
          flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 50%;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 58.33333%;
      -ms-flex: 0 0 58.33333%;
          flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 66.66667%;
      -ms-flex: 0 0 66.66667%;
          flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 75%;
      -ms-flex: 0 0 75%;
          flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 83.33333%;
      -ms-flex: 0 0 83.33333%;
          flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 91.66667%;
      -ms-flex: 0 0 91.66667%;
          flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100%;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  -webkit-box-ordinal-group: 0;
  -webkit-order: -1;
      -ms-flex-order: -1;
          order: -1; }

.order-1 {
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
      -ms-flex-order: 1;
          order: 1; }

.order-2 {
  -webkit-box-ordinal-group: 3;
  -webkit-order: 2;
      -ms-flex-order: 2;
          order: 2; }

.order-3 {
  -webkit-box-ordinal-group: 4;
  -webkit-order: 3;
      -ms-flex-order: 3;
          order: 3; }

.order-4 {
  -webkit-box-ordinal-group: 5;
  -webkit-order: 4;
      -ms-flex-order: 4;
          order: 4; }

.order-5 {
  -webkit-box-ordinal-group: 6;
  -webkit-order: 5;
      -ms-flex-order: 5;
          order: 5; }

.order-6 {
  -webkit-box-ordinal-group: 7;
  -webkit-order: 6;
      -ms-flex-order: 6;
          order: 6; }

.order-7 {
  -webkit-box-ordinal-group: 8;
  -webkit-order: 7;
      -ms-flex-order: 7;
          order: 7; }

.order-8 {
  -webkit-box-ordinal-group: 9;
  -webkit-order: 8;
      -ms-flex-order: 8;
          order: 8; }

.order-9 {
  -webkit-box-ordinal-group: 10;
  -webkit-order: 9;
      -ms-flex-order: 9;
          order: 9; }

.order-10 {
  -webkit-box-ordinal-group: 11;
  -webkit-order: 10;
      -ms-flex-order: 10;
          order: 10; }

.order-11 {
  -webkit-box-ordinal-group: 12;
  -webkit-order: 11;
      -ms-flex-order: 11;
          order: 11; }

.order-12 {
  -webkit-box-ordinal-group: 13;
  -webkit-order: 12;
      -ms-flex-order: 12;
          order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
            flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.33333%;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66667%;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.66667%;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.33333%;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.66667%;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.33333%;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.66667%;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
        -ms-flex-order: -1;
            order: -1; }
  .order-sm-1 {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
        -ms-flex-order: 1;
            order: 1; }
  .order-sm-2 {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
        -ms-flex-order: 2;
            order: 2; }
  .order-sm-3 {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
        -ms-flex-order: 3;
            order: 3; }
  .order-sm-4 {
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
        -ms-flex-order: 4;
            order: 4; }
  .order-sm-5 {
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
        -ms-flex-order: 5;
            order: 5; }
  .order-sm-6 {
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
        -ms-flex-order: 6;
            order: 6; }
  .order-sm-7 {
    -webkit-box-ordinal-group: 8;
    -webkit-order: 7;
        -ms-flex-order: 7;
            order: 7; }
  .order-sm-8 {
    -webkit-box-ordinal-group: 9;
    -webkit-order: 8;
        -ms-flex-order: 8;
            order: 8; }
  .order-sm-9 {
    -webkit-box-ordinal-group: 10;
    -webkit-order: 9;
        -ms-flex-order: 9;
            order: 9; }
  .order-sm-10 {
    -webkit-box-ordinal-group: 11;
    -webkit-order: 10;
        -ms-flex-order: 10;
            order: 10; }
  .order-sm-11 {
    -webkit-box-ordinal-group: 12;
    -webkit-order: 11;
        -ms-flex-order: 11;
            order: 11; }
  .order-sm-12 {
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
        -ms-flex-order: 12;
            order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
            flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.33333%;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66667%;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.66667%;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.33333%;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.66667%;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.33333%;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.66667%;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
        -ms-flex-order: -1;
            order: -1; }
  .order-md-1 {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
        -ms-flex-order: 1;
            order: 1; }
  .order-md-2 {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
        -ms-flex-order: 2;
            order: 2; }
  .order-md-3 {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
        -ms-flex-order: 3;
            order: 3; }
  .order-md-4 {
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
        -ms-flex-order: 4;
            order: 4; }
  .order-md-5 {
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
        -ms-flex-order: 5;
            order: 5; }
  .order-md-6 {
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
        -ms-flex-order: 6;
            order: 6; }
  .order-md-7 {
    -webkit-box-ordinal-group: 8;
    -webkit-order: 7;
        -ms-flex-order: 7;
            order: 7; }
  .order-md-8 {
    -webkit-box-ordinal-group: 9;
    -webkit-order: 8;
        -ms-flex-order: 8;
            order: 8; }
  .order-md-9 {
    -webkit-box-ordinal-group: 10;
    -webkit-order: 9;
        -ms-flex-order: 9;
            order: 9; }
  .order-md-10 {
    -webkit-box-ordinal-group: 11;
    -webkit-order: 10;
        -ms-flex-order: 10;
            order: 10; }
  .order-md-11 {
    -webkit-box-ordinal-group: 12;
    -webkit-order: 11;
        -ms-flex-order: 11;
            order: 11; }
  .order-md-12 {
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
        -ms-flex-order: 12;
            order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
            flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.33333%;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66667%;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.66667%;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.33333%;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.66667%;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.33333%;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.66667%;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
        -ms-flex-order: -1;
            order: -1; }
  .order-lg-1 {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
        -ms-flex-order: 1;
            order: 1; }
  .order-lg-2 {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
        -ms-flex-order: 2;
            order: 2; }
  .order-lg-3 {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
        -ms-flex-order: 3;
            order: 3; }
  .order-lg-4 {
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
        -ms-flex-order: 4;
            order: 4; }
  .order-lg-5 {
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
        -ms-flex-order: 5;
            order: 5; }
  .order-lg-6 {
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
        -ms-flex-order: 6;
            order: 6; }
  .order-lg-7 {
    -webkit-box-ordinal-group: 8;
    -webkit-order: 7;
        -ms-flex-order: 7;
            order: 7; }
  .order-lg-8 {
    -webkit-box-ordinal-group: 9;
    -webkit-order: 8;
        -ms-flex-order: 8;
            order: 8; }
  .order-lg-9 {
    -webkit-box-ordinal-group: 10;
    -webkit-order: 9;
        -ms-flex-order: 9;
            order: 9; }
  .order-lg-10 {
    -webkit-box-ordinal-group: 11;
    -webkit-order: 10;
        -ms-flex-order: 10;
            order: 10; }
  .order-lg-11 {
    -webkit-box-ordinal-group: 12;
    -webkit-order: 11;
        -ms-flex-order: 11;
            order: 11; }
  .order-lg-12 {
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
        -ms-flex-order: 12;
            order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
            flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.33333%;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66667%;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.66667%;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.33333%;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.66667%;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.33333%;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.66667%;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
        -ms-flex-order: -1;
            order: -1; }
  .order-xl-1 {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
        -ms-flex-order: 1;
            order: 1; }
  .order-xl-2 {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
        -ms-flex-order: 2;
            order: 2; }
  .order-xl-3 {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
        -ms-flex-order: 3;
            order: 3; }
  .order-xl-4 {
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
        -ms-flex-order: 4;
            order: 4; }
  .order-xl-5 {
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
        -ms-flex-order: 5;
            order: 5; }
  .order-xl-6 {
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
        -ms-flex-order: 6;
            order: 6; }
  .order-xl-7 {
    -webkit-box-ordinal-group: 8;
    -webkit-order: 7;
        -ms-flex-order: 7;
            order: 7; }
  .order-xl-8 {
    -webkit-box-ordinal-group: 9;
    -webkit-order: 8;
        -ms-flex-order: 8;
            order: 8; }
  .order-xl-9 {
    -webkit-box-ordinal-group: 10;
    -webkit-order: 9;
        -ms-flex-order: 9;
            order: 9; }
  .order-xl-10 {
    -webkit-box-ordinal-group: 11;
    -webkit-order: 10;
        -ms-flex-order: 10;
            order: 10; }
  .order-xl-11 {
    -webkit-box-ordinal-group: 12;
    -webkit-order: 11;
        -ms-flex-order: 11;
            order: 11; }
  .order-xl-12 {
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
        -ms-flex-order: 12;
            order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -webkit-flex-direction: row !important;
      -ms-flex-direction: row !important;
          flex-direction: row !important; }

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -webkit-flex-direction: column !important;
      -ms-flex-direction: column !important;
          flex-direction: column !important; }

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  -webkit-flex-direction: row-reverse !important;
      -ms-flex-direction: row-reverse !important;
          flex-direction: row-reverse !important; }

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
  -webkit-flex-direction: column-reverse !important;
      -ms-flex-direction: column-reverse !important;
          flex-direction: column-reverse !important; }

.flex-wrap {
  -webkit-flex-wrap: wrap !important;
      -ms-flex-wrap: wrap !important;
          flex-wrap: wrap !important; }

.flex-nowrap {
  -webkit-flex-wrap: nowrap !important;
      -ms-flex-wrap: nowrap !important;
          flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  -webkit-flex-wrap: wrap-reverse !important;
      -ms-flex-wrap: wrap-reverse !important;
          flex-wrap: wrap-reverse !important; }

.justify-content-start {
  -webkit-box-pack: start !important;
  -webkit-justify-content: flex-start !important;
      -ms-flex-pack: start !important;
          justify-content: flex-start !important; }

.justify-content-end {
  -webkit-box-pack: end !important;
  -webkit-justify-content: flex-end !important;
      -ms-flex-pack: end !important;
          justify-content: flex-end !important; }

.justify-content-center {
  -webkit-box-pack: center !important;
  -webkit-justify-content: center !important;
      -ms-flex-pack: center !important;
          justify-content: center !important; }

.justify-content-between {
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
      -ms-flex-pack: justify !important;
          justify-content: space-between !important; }

.justify-content-around {
  -webkit-justify-content: space-around !important;
      -ms-flex-pack: distribute !important;
          justify-content: space-around !important; }

.align-items-start {
  -webkit-box-align: start !important;
  -webkit-align-items: flex-start !important;
      -ms-flex-align: start !important;
          align-items: flex-start !important; }

.align-items-end {
  -webkit-box-align: end !important;
  -webkit-align-items: flex-end !important;
      -ms-flex-align: end !important;
          align-items: flex-end !important; }

.align-items-center {
  -webkit-box-align: center !important;
  -webkit-align-items: center !important;
      -ms-flex-align: center !important;
          align-items: center !important; }

.align-items-baseline {
  -webkit-box-align: baseline !important;
  -webkit-align-items: baseline !important;
      -ms-flex-align: baseline !important;
          align-items: baseline !important; }

.align-items-stretch {
  -webkit-box-align: stretch !important;
  -webkit-align-items: stretch !important;
      -ms-flex-align: stretch !important;
          align-items: stretch !important; }

.align-content-start {
  -webkit-align-content: flex-start !important;
      -ms-flex-line-pack: start !important;
          align-content: flex-start !important; }

.align-content-end {
  -webkit-align-content: flex-end !important;
      -ms-flex-line-pack: end !important;
          align-content: flex-end !important; }

.align-content-center {
  -webkit-align-content: center !important;
      -ms-flex-line-pack: center !important;
          align-content: center !important; }

.align-content-between {
  -webkit-align-content: space-between !important;
      -ms-flex-line-pack: justify !important;
          align-content: space-between !important; }

.align-content-around {
  -webkit-align-content: space-around !important;
      -ms-flex-line-pack: distribute !important;
          align-content: space-around !important; }

.align-content-stretch {
  -webkit-align-content: stretch !important;
      -ms-flex-line-pack: stretch !important;
          align-content: stretch !important; }

.align-self-auto {
  -webkit-align-self: auto !important;
      -ms-flex-item-align: auto !important;
          align-self: auto !important; }

.align-self-start {
  -webkit-align-self: flex-start !important;
      -ms-flex-item-align: start !important;
          align-self: flex-start !important; }

.align-self-end {
  -webkit-align-self: flex-end !important;
      -ms-flex-item-align: end !important;
          align-self: flex-end !important; }

.align-self-center {
  -webkit-align-self: center !important;
      -ms-flex-item-align: center !important;
          align-self: center !important; }

.align-self-baseline {
  -webkit-align-self: baseline !important;
      -ms-flex-item-align: baseline !important;
          align-self: baseline !important; }

.align-self-stretch {
  -webkit-align-self: stretch !important;
      -ms-flex-item-align: stretch !important;
          align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: row !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: column !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: row-reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: column-reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    -webkit-flex-wrap: wrap !important;
        -ms-flex-wrap: wrap !important;
            flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    -webkit-flex-wrap: nowrap !important;
        -ms-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
        -ms-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    -webkit-box-pack: start !important;
    -webkit-justify-content: flex-start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-sm-end {
    -webkit-box-pack: end !important;
    -webkit-justify-content: flex-end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-sm-center {
    -webkit-box-pack: center !important;
    -webkit-justify-content: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important; }
  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-sm-around {
    -webkit-justify-content: space-around !important;
        -ms-flex-pack: distribute !important;
            justify-content: space-around !important; }
  .align-items-sm-start {
    -webkit-box-align: start !important;
    -webkit-align-items: flex-start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important; }
  .align-items-sm-end {
    -webkit-box-align: end !important;
    -webkit-align-items: flex-end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important; }
  .align-items-sm-center {
    -webkit-box-align: center !important;
    -webkit-align-items: center !important;
        -ms-flex-align: center !important;
            align-items: center !important; }
  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
    -webkit-align-items: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important; }
  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
    -webkit-align-items: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important; }
  .align-content-sm-start {
    -webkit-align-content: flex-start !important;
        -ms-flex-line-pack: start !important;
            align-content: flex-start !important; }
  .align-content-sm-end {
    -webkit-align-content: flex-end !important;
        -ms-flex-line-pack: end !important;
            align-content: flex-end !important; }
  .align-content-sm-center {
    -webkit-align-content: center !important;
        -ms-flex-line-pack: center !important;
            align-content: center !important; }
  .align-content-sm-between {
    -webkit-align-content: space-between !important;
        -ms-flex-line-pack: justify !important;
            align-content: space-between !important; }
  .align-content-sm-around {
    -webkit-align-content: space-around !important;
        -ms-flex-line-pack: distribute !important;
            align-content: space-around !important; }
  .align-content-sm-stretch {
    -webkit-align-content: stretch !important;
        -ms-flex-line-pack: stretch !important;
            align-content: stretch !important; }
  .align-self-sm-auto {
    -webkit-align-self: auto !important;
        -ms-flex-item-align: auto !important;
            align-self: auto !important; }
  .align-self-sm-start {
    -webkit-align-self: flex-start !important;
        -ms-flex-item-align: start !important;
            align-self: flex-start !important; }
  .align-self-sm-end {
    -webkit-align-self: flex-end !important;
        -ms-flex-item-align: end !important;
            align-self: flex-end !important; }
  .align-self-sm-center {
    -webkit-align-self: center !important;
        -ms-flex-item-align: center !important;
            align-self: center !important; }
  .align-self-sm-baseline {
    -webkit-align-self: baseline !important;
        -ms-flex-item-align: baseline !important;
            align-self: baseline !important; }
  .align-self-sm-stretch {
    -webkit-align-self: stretch !important;
        -ms-flex-item-align: stretch !important;
            align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: row !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: column !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: row-reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: column-reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-md-wrap {
    -webkit-flex-wrap: wrap !important;
        -ms-flex-wrap: wrap !important;
            flex-wrap: wrap !important; }
  .flex-md-nowrap {
    -webkit-flex-wrap: nowrap !important;
        -ms-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
        -ms-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    -webkit-box-pack: start !important;
    -webkit-justify-content: flex-start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-md-end {
    -webkit-box-pack: end !important;
    -webkit-justify-content: flex-end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-md-center {
    -webkit-box-pack: center !important;
    -webkit-justify-content: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important; }
  .justify-content-md-between {
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-md-around {
    -webkit-justify-content: space-around !important;
        -ms-flex-pack: distribute !important;
            justify-content: space-around !important; }
  .align-items-md-start {
    -webkit-box-align: start !important;
    -webkit-align-items: flex-start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important; }
  .align-items-md-end {
    -webkit-box-align: end !important;
    -webkit-align-items: flex-end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important; }
  .align-items-md-center {
    -webkit-box-align: center !important;
    -webkit-align-items: center !important;
        -ms-flex-align: center !important;
            align-items: center !important; }
  .align-items-md-baseline {
    -webkit-box-align: baseline !important;
    -webkit-align-items: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important; }
  .align-items-md-stretch {
    -webkit-box-align: stretch !important;
    -webkit-align-items: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important; }
  .align-content-md-start {
    -webkit-align-content: flex-start !important;
        -ms-flex-line-pack: start !important;
            align-content: flex-start !important; }
  .align-content-md-end {
    -webkit-align-content: flex-end !important;
        -ms-flex-line-pack: end !important;
            align-content: flex-end !important; }
  .align-content-md-center {
    -webkit-align-content: center !important;
        -ms-flex-line-pack: center !important;
            align-content: center !important; }
  .align-content-md-between {
    -webkit-align-content: space-between !important;
        -ms-flex-line-pack: justify !important;
            align-content: space-between !important; }
  .align-content-md-around {
    -webkit-align-content: space-around !important;
        -ms-flex-line-pack: distribute !important;
            align-content: space-around !important; }
  .align-content-md-stretch {
    -webkit-align-content: stretch !important;
        -ms-flex-line-pack: stretch !important;
            align-content: stretch !important; }
  .align-self-md-auto {
    -webkit-align-self: auto !important;
        -ms-flex-item-align: auto !important;
            align-self: auto !important; }
  .align-self-md-start {
    -webkit-align-self: flex-start !important;
        -ms-flex-item-align: start !important;
            align-self: flex-start !important; }
  .align-self-md-end {
    -webkit-align-self: flex-end !important;
        -ms-flex-item-align: end !important;
            align-self: flex-end !important; }
  .align-self-md-center {
    -webkit-align-self: center !important;
        -ms-flex-item-align: center !important;
            align-self: center !important; }
  .align-self-md-baseline {
    -webkit-align-self: baseline !important;
        -ms-flex-item-align: baseline !important;
            align-self: baseline !important; }
  .align-self-md-stretch {
    -webkit-align-self: stretch !important;
        -ms-flex-item-align: stretch !important;
            align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: row !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: column !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: row-reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: column-reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    -webkit-flex-wrap: wrap !important;
        -ms-flex-wrap: wrap !important;
            flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    -webkit-flex-wrap: nowrap !important;
        -ms-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
        -ms-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    -webkit-box-pack: start !important;
    -webkit-justify-content: flex-start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-lg-end {
    -webkit-box-pack: end !important;
    -webkit-justify-content: flex-end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-lg-center {
    -webkit-box-pack: center !important;
    -webkit-justify-content: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important; }
  .justify-content-lg-between {
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-lg-around {
    -webkit-justify-content: space-around !important;
        -ms-flex-pack: distribute !important;
            justify-content: space-around !important; }
  .align-items-lg-start {
    -webkit-box-align: start !important;
    -webkit-align-items: flex-start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important; }
  .align-items-lg-end {
    -webkit-box-align: end !important;
    -webkit-align-items: flex-end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important; }
  .align-items-lg-center {
    -webkit-box-align: center !important;
    -webkit-align-items: center !important;
        -ms-flex-align: center !important;
            align-items: center !important; }
  .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
    -webkit-align-items: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important; }
  .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
    -webkit-align-items: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important; }
  .align-content-lg-start {
    -webkit-align-content: flex-start !important;
        -ms-flex-line-pack: start !important;
            align-content: flex-start !important; }
  .align-content-lg-end {
    -webkit-align-content: flex-end !important;
        -ms-flex-line-pack: end !important;
            align-content: flex-end !important; }
  .align-content-lg-center {
    -webkit-align-content: center !important;
        -ms-flex-line-pack: center !important;
            align-content: center !important; }
  .align-content-lg-between {
    -webkit-align-content: space-between !important;
        -ms-flex-line-pack: justify !important;
            align-content: space-between !important; }
  .align-content-lg-around {
    -webkit-align-content: space-around !important;
        -ms-flex-line-pack: distribute !important;
            align-content: space-around !important; }
  .align-content-lg-stretch {
    -webkit-align-content: stretch !important;
        -ms-flex-line-pack: stretch !important;
            align-content: stretch !important; }
  .align-self-lg-auto {
    -webkit-align-self: auto !important;
        -ms-flex-item-align: auto !important;
            align-self: auto !important; }
  .align-self-lg-start {
    -webkit-align-self: flex-start !important;
        -ms-flex-item-align: start !important;
            align-self: flex-start !important; }
  .align-self-lg-end {
    -webkit-align-self: flex-end !important;
        -ms-flex-item-align: end !important;
            align-self: flex-end !important; }
  .align-self-lg-center {
    -webkit-align-self: center !important;
        -ms-flex-item-align: center !important;
            align-self: center !important; }
  .align-self-lg-baseline {
    -webkit-align-self: baseline !important;
        -ms-flex-item-align: baseline !important;
            align-self: baseline !important; }
  .align-self-lg-stretch {
    -webkit-align-self: stretch !important;
        -ms-flex-item-align: stretch !important;
            align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: row !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: column !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: row-reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: column-reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    -webkit-flex-wrap: wrap !important;
        -ms-flex-wrap: wrap !important;
            flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    -webkit-flex-wrap: nowrap !important;
        -ms-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
        -ms-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    -webkit-box-pack: start !important;
    -webkit-justify-content: flex-start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-xl-end {
    -webkit-box-pack: end !important;
    -webkit-justify-content: flex-end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-xl-center {
    -webkit-box-pack: center !important;
    -webkit-justify-content: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important; }
  .justify-content-xl-between {
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-xl-around {
    -webkit-justify-content: space-around !important;
        -ms-flex-pack: distribute !important;
            justify-content: space-around !important; }
  .align-items-xl-start {
    -webkit-box-align: start !important;
    -webkit-align-items: flex-start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important; }
  .align-items-xl-end {
    -webkit-box-align: end !important;
    -webkit-align-items: flex-end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important; }
  .align-items-xl-center {
    -webkit-box-align: center !important;
    -webkit-align-items: center !important;
        -ms-flex-align: center !important;
            align-items: center !important; }
  .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
    -webkit-align-items: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important; }
  .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
    -webkit-align-items: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important; }
  .align-content-xl-start {
    -webkit-align-content: flex-start !important;
        -ms-flex-line-pack: start !important;
            align-content: flex-start !important; }
  .align-content-xl-end {
    -webkit-align-content: flex-end !important;
        -ms-flex-line-pack: end !important;
            align-content: flex-end !important; }
  .align-content-xl-center {
    -webkit-align-content: center !important;
        -ms-flex-line-pack: center !important;
            align-content: center !important; }
  .align-content-xl-between {
    -webkit-align-content: space-between !important;
        -ms-flex-line-pack: justify !important;
            align-content: space-between !important; }
  .align-content-xl-around {
    -webkit-align-content: space-around !important;
        -ms-flex-line-pack: distribute !important;
            align-content: space-around !important; }
  .align-content-xl-stretch {
    -webkit-align-content: stretch !important;
        -ms-flex-line-pack: stretch !important;
            align-content: stretch !important; }
  .align-self-xl-auto {
    -webkit-align-self: auto !important;
        -ms-flex-item-align: auto !important;
            align-self: auto !important; }
  .align-self-xl-start {
    -webkit-align-self: flex-start !important;
        -ms-flex-item-align: start !important;
            align-self: flex-start !important; }
  .align-self-xl-end {
    -webkit-align-self: flex-end !important;
        -ms-flex-item-align: end !important;
            align-self: flex-end !important; }
  .align-self-xl-center {
    -webkit-align-self: center !important;
        -ms-flex-item-align: center !important;
            align-self: center !important; }
  .align-self-xl-baseline {
    -webkit-align-self: baseline !important;
        -ms-flex-item-align: baseline !important;
            align-self: baseline !important; }
  .align-self-xl-stretch {
    -webkit-align-self: stretch !important;
        -ms-flex-item-align: stretch !important;
            align-self: stretch !important; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.border {
  border: 1px solid #e9ecef !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #868e96 !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.pc_Banner .slick-slide {
  height: 371px; }

.mo_Banner .slick-slide {
  height: 200px; }

/* Slider */
/* Icons */
@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
  src: url(../assets/slick.eot?ced611da);
  src: url(../assets/slick.eot?ced611da?#iefix) format("embedded-opentype"), url(../assets/slick.woff?b7c9e1e4) format("woff"), url(../assets/slick.ttf?d41f55a7) format("truetype"), url(../assets/slick.svg?f97e3bbf#slick) format("svg"); }

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  z-index: 99; }

/*.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}*/
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1; }

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 1; }

.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: 1;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px;
  /*    background: url(../images/are.png) 8px 0 no-repeat;*/ }

.slick-next {
  right: -25px;
  /*    background: url(../images/are.png) -10px 0 no-repeat;*/ }

/* Dots */
.slick-dots {
  position: absolute;
  display: block;
  width: 100%;
  padding: 0;
  margin: -39px 0 0 0;
  list-style: none;
  text-align: center; }

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 11px;
  height: 11px !important;
  margin: 0 10px;
  padding: 0;
  cursor: pointer; }

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent; }

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
  background: transparent !important; }

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1; }

.slick-dots li button:before {
  font-size: 6px;
  line-height: 20px;
  display: table;
  position: absolute;
  top: 2px;
  left: 2px;
  width: 7px;
  height: 7px;
  border-radius: 99em;
  background: #fff;
  content: '';
  text-align: center;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-dots li.slick-active button:before {
  background: #000; }

.topContent .slick-next, .topContent .slick-prev {
  display: none  !important; }

.topContent .slick-dots li {
  width: 20px; }

.topContent .slick-dots {
  position: relative;
  margin: 29px 0 0 0; }

.tagContent .slick-dots {
  margin: 0px 0 0 0; }

.tagContent .slick-dots li button:before {
  border: none; }

.exposeCase .topicContent .slick-dots {
  margin: 0px 0 0 0; }

.exposeCase .topicContent .slick-dots li {
  width: 20px; }

.exposeCase .topicContent .slick-dots li button:before {
  border: none;
  background: #959595; }

.exposeCase .topicContent .slick-dots li.slick-active button:before {
  border: none;
  background: #6bad3d; }

.learningBox .exposeCase .topicContent li {
  margin: 0px 4px 20px 0; }

.exposeCase .slick-prev {
  width: 46px;
  height: 108px;
  display: block;
  /* background: url(../images/le_ribtn.jpg) top left no-repeat; */
  left: -46px; }

.exposeCase .slick-next {
  width: 46px;
  height: 108px;
  display: block;
  /* background: url(../images/ex_ribtn.jpg) top left no-repeat; */
  right: -29px; }

.navigation .tagContent .slick-dots {
  margin: -13px 0 0 0;
  width: auto;
  text-align: right;
  position: relative;
  bottom: -11px; }

.eventBox .navigation .tagContent .slick-dots {
  margin: -13px 0 0 0;
  width: auto;
  text-align: right;
  position: relative;
  bottom: -26px; }

.navigation .slick-dots li button:before {
  background: #959595; }

.navigation .slick-dots li.slick-active button:before {
  background: #6bad3d; }

.recentBox .slick-dots {
  display: none !important; }

.recentBox .slick-list {
  width: 90%;
  max-width: 1000px;
  margin: 0 auto; }

.banner .slick-prev, .recentBox .slick-prev {
  left: 0px;
  width: 40px;
  height: 93px; }

.banner .slick-next, .recentBox .slick-next {
  right: 0px;
  width: 40px;
  height: 93px; }

/******************************************/
/*		base 歸零 變數
/******************************************/
/* http://meyerweb.com/eric/tools/css/reset/ */
/* v1.0 | 20080212 */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
caption,
tfoot,
thead,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  /* vertical-align: baseline; */ }

tr,
th,
td {
  margin: 0;
  padding: 0;
  font-size: 100%;
  font: inherit; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

/*ol, ul {
	list-style: none;
}*/
blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

/*背景沒連結時用*/
.myMOUSE {
  cursor: default; }

.error {
  font-size: 12px;
  color: #fc7314; }

@font-face {
  font-family: "DINPro-Regular";
  src: url(../assets/DINPro-Regular_13937.ttf?86fda987); }

@font-face {
  font-family: "DIN-Black";
  src: url(../assets/DINPro-Bold_13934.ttf?05078759); }

/*把變數帶入mixin中，此mixin只就單純把media queries寫到mixin中，
並且使用上面設立的變數來調動media queries的width*/
main[role="main"], section, article, .editor_Box, .form-group {
  zoom: 1; }
  main[role="main"]:before, section:before, article:before, .editor_Box:before, .form-group:before, main[role="main"]:after, section:after, article:after, .editor_Box:after, .form-group:after {
    content: "";
    display: table;
    height: 0;
    overflow: hidden; }
  main[role="main"]:after, section:after, article:after, .editor_Box:after, .form-group:after {
    clear: both; }

/******************************************/
/*		layout 共用
/******************************************/
/*----------------------通用設定------------------------*/
p::-moz-selection,
h1::-moz-selection,
h2::-moz-selection,
h3::-moz-selection,
h4::-moz-selection,
b::-moz-selection,
strong::-moz-selection,
span::-moz-selection,
li::-moz-selection,
div::-moz-selection,
a::-moz-selection,
img::-moz-selection,
tr::-moz-selection,
td::-moz-selection,
th::-moz-selection,
address::-moz-selection {
  color: #ffffff;
  background: #c60a26; }
p::selection,
h1::selection,
h2::selection,
h3::selection,
h4::selection,
b::selection,
strong::selection,
span::selection,
li::selection,
div::selection,
a::selection,
img::selection,
tr::selection,
td::selection,
th::selection,
address::selection {
  color: #ffffff;
  background: #c60a26; }

body {
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  min-height: 100%;
  line-height: 1em;
  overflow: auto;
  font-size: 1rem;
  color: #000;
  word-break: break-word;
  letter-spacing: 0.05em;
  font-family: "微軟正黑體", Arial, sans-serif;
  z-index: 0; }

a {
  text-decoration: none;
  outline: 0; }

a,
a:hover {
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s; }

img {
  vertical-align: middle;
  max-width: 100%; }

a[x-apple-data-detectors] {
  color: inherit !important;
  text-decoration: none !important;
  font-size: inherit !important;
  font-family: inherit !important;
  font-weight: inherit !important;
  line-height: inherit !important; }

a[href^="tel"] {
  color: inherit;
  /* Inherit text color of parent element. */
  text-decoration: none;
  /* Remove underline. */
  /* Additional css `propery: value;` pairs here… */ }

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.jqimgFill {
  position: relative;
  overflow: hidden;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: inline-block;
  -webkit-transition: opacity .4s ease-in-out, -webkit-transform .4s ease-in-out;
  transition: opacity .4s ease-in-out, -webkit-transform .4s ease-in-out;
  -o-transition: opacity .4s ease-in-out, transform .4s ease-in-out;
  transition: opacity .4s ease-in-out, transform .4s ease-in-out;
  transition: opacity .4s ease-in-out, transform .4s ease-in-out, -webkit-transform .4s ease-in-out; }

.jqimgFill img,
.jqimgFill-tc img {
  left: 0;
  top: 0;
  margin: 0;
  display: block;
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  max-height: none;
  max-width: none;
  -webkit-transition: opacity .4s ease-in-out, -webkit-transform .4s ease-in-out;
  transition: opacity .4s ease-in-out, -webkit-transform .4s ease-in-out;
  -o-transition: opacity .4s ease-in-out, transform .4s ease-in-out;
  transition: opacity .4s ease-in-out, transform .4s ease-in-out;
  transition: opacity .4s ease-in-out, transform .4s ease-in-out, -webkit-transform .4s ease-in-out; }

input,
select,
textarea {
  width: 100%;
  height: 50px;
  outline: none;
  padding: 0 10px;
  color: #000;
  background: #eeeeee;
  border: 1px solid #ffffff;
  font-size: 1rem; }
  @media screen and (max-width: 640px) {
    input,
    select,
    textarea {
      font-size: 0.9375rem; } }
  input:focus,
  select:focus,
  textarea:focus {
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    border: 1px solid #eee; }

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  background: #000 !important; }

textarea {
  height: 173px;
  padding: 10px; }

::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #999; }

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #999; }

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #999; }

:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #999; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0; }

input[type="number"] {
  -moz-appearance: textfield; }

select {
  font-family: "微軟正黑體", Arial, sans-serif;
  background-size: 3.4%;
  padding-right: 14px;
  height: 40px;
  width: 100%; }

.selectBox {
  background: #FFF; }

body :target:before {
  display: block;
  content: "";
  height: 120px;
  margin: -120px 0 0; }
  @media screen and (max-width: 960px) {
    body :target:before {
      height: 100px;
      margin: -100px 0 0; } }

body .slide-down :target:before {
  display: block;
  content: "";
  height: 120px;
  margin: -120px 0 0; }

.slick-slide {
  outline: 0; }

.slick-next, .slick-prev {
  z-index: 5; }

/*----------------------main_banner------------------------*/
.main_banner {
  width: 100%;
  height: 332px; }

.container_Box {
  max-width: 1170px;
  width: 95%;
  margin: 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }

.left_box {
  width: 30%;
  max-width: 300px;
  margin-right: 28px; }

.left_nav {
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
  max-width: 284px; }

.right_content {
  width: calc(100% - 285px);
  max-width: 830px; }
  .right_content .we-content {
    border-radius: 10px;
    background-color: #ffffff;
    padding: 36px 40px;
    -webkit-box-shadow: 0 0 4px 0 rgba(13, 5, 9, 0.2);
            box-shadow: 0 0 4px 0 rgba(13, 5, 9, 0.2);
    margin-bottom: 19px; }
  .right_content header {
    position: relative;
    padding-bottom: 16px;
    margin-bottom: 30px; }
    .right_content header h1 {
      position: relative;
      width: 100%;
      font-size: 1.875rem;
      line-height: 1.2;
      letter-spacing: 1.5px;
      text-align: left;
      color: #c60a26;
      margin-bottom: 13px;
      border-bottom: 1px solid #c60a26;
      padding-bottom: 11.5px;
      font-weight: bold; }
      .right_content header h1::before {
        width: 30px;
        height: 21px;
        display: inline-block;
        content: "";
        background: url(../svg/wechat_icon.svg?2be820a0) top left no-repeat;
        background-size: contain;
        margin-right: 13px;
        text-align: right; }
      .right_content header h1:after {
        position: absolute;
        bottom: -6px;
        right: 0px;
        content: "";
        width: 13px;
        height: 13px;
        background: #c60a26;
        border-radius: 99px; }
    .right_content header .status {
      width: 86%;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
          -ms-flex-pack: justify;
              justify-content: space-between;
      position: absolute;
      -webkit-box-align: center;
      -webkit-align-items: center;
          -ms-flex-align: center;
              align-items: center; }
      @media screen and (max-width: 1036px) {
        .right_content header .status {
          width: 82%; } }
      @media screen and (max-width: 480px) {
        .right_content header .status {
          width: 63%; } }
    .right_content header time {
      font-weight: bold;
      text-align: right;
      font-size: 0.875rem;
      color: #333333;
      display: block; }
      @media screen and (max-width: 480px) {
        .right_content header time {
          font-size: 0.75rem; } }
    .right_content header .fb-like {
      width: 100%;
      text-align: right;
      display: block !important; }
    .right_content header time + .fb-like {
      position: relative;
      top: -17px;
      margin-bottom: -9px;
      display: block; }
  .right_content .case_title {
    position: relative;
    width: 100%;
    font-size: 1rem;
    line-height: 1.2;
    letter-spacing: 1.5px;
    text-align: left;
    color: #000;
    border-bottom: 1px solid #5797BD;
    padding-bottom: 24px;
    margin-bottom: 13px; }
    .right_content .case_title::after {
      display: block;
      content: '';
      width: 103px;
      height: 4px;
      background: #5797BD;
      position: absolute;
      bottom: 0; }
    .right_content .case_title h1 {
      padding-bottom: 6px;
      margin-bottom: 0;
      border-bottom: none; }
      .right_content .case_title h1::after {
        display: none; }
    .right_content .case_title h3 {
      line-height: 1.3; }

/*----------------------title------------------------*/
.case_box h2,
.qa_item h2,
.from_box h2 {
  font-size: 1.875rem;
  line-height: 1.33;
  letter-spacing: 1.5px;
  color: #5797BD;
  margin-bottom: 30px; }

.title {
  position: relative;
  width: 100%;
  padding: 22px 0 22px 0px;
  margin-bottom: 12px;
  border-radius: 5px;
  background-color: #009a85;
  text-align: center;
  background-size: cover; }
  .title::before, .title::after {
    content: '';
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 99em;
    background-color: #d1dee8; }
  .title::before {
    left: 5%; }
  .title::after {
    right: 5%; }
  .title h2 {
    color: #ffffff;
    font-size: 1.375rem;
    line-height: 1; }

/*----------------------left_btn------------------------*/
.item_nav {
  font-size: 1rem;
  letter-spacing: 0.9px;
  padding: 0 3px 20px 3px; }
  .item_nav li {
    list-style: none;
    font-weight: bold;
    margin-bottom: 10px; }
  .item_nav > ul > li {
    margin-bottom: 0.5em;
    line-height: 1.2; }
    .item_nav > ul > li.active > a {
      margin-bottom: 10px;
      border-radius: 3px;
      background-color: #ffffff; }
  .item_nav > ul > li > a {
    position: relative;
    width: 100%;
    display: block;
    text-decoration: none;
    color: #003126;
    padding: 10px 19px;
    font-weight: bold; }
    .item_nav > ul > li > a::before {
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      background: #009a85;
      border-radius: 99em;
      margin-right: 10px; }
    .item_nav > ul > li > a:hover {
      color: #c60a26; }

.physician_detail_Page .item_nav > ul > li > a:after {
  -webkit-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
  display: block;
  content: '';
  position: absolute;
  top: 41%;
  right: 10px;
  width: 8.5px;
  height: 14px;
  background: url(../images/arrw_3.png?70cb95c4) no-repeat;
  background-size: contain;
  -webkit-transform: translateX(-50%) rotate(-90deg);
      -ms-transform: translateX(-50%) rotate(-90deg);
          transform: translateX(-50%) rotate(-90deg); }

.item_nav > ul > li.hit > a::after {
  top: 50%;
  right: 15px;
  -webkit-transform: translateX(-50%) rotate(0deg);
      -ms-transform: translateX(-50%) rotate(0deg);
          transform: translateX(-50%) rotate(0deg); }

.hit .item_btn.open {
  display: block; }

.hit .item_btn li.active a {
  color: #ffffff;
  background-color: #e6d4bd;
  border-radius: 3px; }

.item_btn {
  display: none;
  font-size: 0.9375rem; }
  .item_btn a {
    display: block;
    color: #baa98d;
    text-decoration: none;
    padding: 11px 15px 11px 31px;
    width: 94%;
    margin: auto; }
    .item_btn a span {
      display: inline-block;
      vertical-align: top; }
    .item_btn a:hover {
      color: #d1a369; }

/*----------------------麵包屑------------------------*/
.path {
  width: 100%;
  text-align: right;
  font-size: 0.875rem;
  color: #333333;
  margin-top: 25px;
  margin-bottom: 27px; }
  .path a {
    color: #333333;
    text-decoration: none; }
    .path a:after {
      content: ' / '; }
    .path a:hover {
      color: #009a85; }

/*----------------------大區塊------------------------*/
#Wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  min-height: 100%;
  min-width: 320px !important;
  overflow: hidden;
  z-index: 2; }

main[role="main"] {
  display: block;
  width: 100%;
  position: relative;
  margin-top: 100px;
  padding-bottom: 89px;
  background: #d1dee8;
  background-size: contain; }

.ariter_bg {
  width: 100%; }

section {
  position: relative; }

article {
  width: 1200px;
  max-width: 90%;
  margin: 0 auto; }

/*----------------------每個選單Highlight------------------------*/
/*----------------------內頁單元標題------------------------*/
.title_box {
  margin: 0 auto 20px auto;
  text-align: center;
  font-family: "DINPro-Regular";
  font-size: 1rem;
  line-height: 24px;
  color: #444; }

/*----------------------編輯器------------------------*/
.editor_Content {
  position: relative;
  width: 100%;
  margin: 0 auto;
  background: #ffffff;
  padding: 79px 0; }

.editor_Box {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  margin: auto;
  line-height: 30px;
  font-size: 1rem; }
  .editor_Box ul,
  .editor_Box ol {
    padding-left: 40px; }
  .editor_Box img {
    max-width: 100%;
    height: auto !important; }
  .editor_Box iframe {
    max-width: 100%; }
  .editor_Box h1,
  .editor_Box h2,
  .editor_Box h3,
  .editor_Box h4,
  .editor_Box h5,
  .editor_Box h6 {
    margin: inherit; }

.mo_use {
  display: none; }

/*----------------------300x250廣告------------------------*/
.advertising {
  max-width: 300px;
  margin-top: 19px; }
  .advertising a {
    display: block;
    width: 100%;
    height: 100%; }
    .advertising a:not(:last-child) {
      margin-bottom: 10px; }
  .advertising img {
    width: 100%; }
  @media (max-width: 991.99px) {
    .advertising {
      display: none; } }

/*----------------------back------------------------*/
.back_btn {
  width: 100%;
  text-align: center;
  margin-top: 40px;
  font-size: 1rem;
  letter-spacing: 0;
  font-weight: 600; }
  .back_btn a {
    margin: auto;
    height: 40px;
    display: inline-block;
    color: #79be62;
    background-color: #eeeeee;
    text-decoration: none;
    padding: 0 45px;
    line-height: 40px; }
    .back_btn a:hover {
      opacity: 0.7; }

.slick-list {
  margin-bottom: 40px; }

.slick-dots {
  position: relative;
  margin: 0; }
  .slick-dots li {
    width: auto;
    height: auto !important;
    margin: 0 6px 0 0; }
    .slick-dots li.slick-active button:before, .slick-dots li.slick-active div:before {
      background-color: #cb102a; }
  .slick-dots li button, .slick-dots li div {
    display: block;
    width: 42px;
    height: 6px; }
    .slick-dots li button:before, .slick-dots li div:before {
      content: '';
      display: block;
      -webkit-transition: .4s;
      -o-transition: .4s;
      transition: .4s;
      width: 100%;
      height: 6px;
      background-color: #005846;
      border-radius: 0; }
    .slick-dots li button:hover::before, .slick-dots li div:hover::before {
      background-color: #cb102a; }

/*----------------------上一則 下一則------------------------*/
.ud_btn {
  width: 100%;
  margin-top: 20px; }

.ud_l,
.ud_r {
  width: 100%;
  display: block;
  font-size: 1rem;
  line-height: 2.25;
  letter-spacing: 0.8px;
  text-align: left;
  text-decoration: none;
  color: #009a85; }
  .ud_l:hover,
  .ud_r:hover {
    color: #c60a26; }

/*----------------------GO TOP------------------------*/
/*----------------------頁數------------------------*/
.page {
  font-size: 0.875rem;
  clear: both;
  width: 90%;
  max-width: 530px;
  text-align: center;
  margin: 0 auto;
  border-radius: 99em; }
  .page dt,
  .page dd {
    vertical-align: middle;
    display: inline-block; }
    .page dt a,
    .page dd a {
      display: block;
      width: 23px;
      height: 23px;
      line-height: 22px;
      text-decoration: none;
      color: #c60a26; }
      .page dt a:hover,
      .page dd a:hover {
        color: #ffffff;
        background: #c60a26; }
  .page dd:not(:last-child) {
    margin-right: 4px; }
  .page dd.active a {
    background: none;
    padding: 0;
    font-weight: bold;
    color: #ffffff;
    background: #c60a26; }
  .page dt.rtbn a,
  .page dt.ltbn a {
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s; }
  .page .nopage {
    opacity: 0.2; }

@media screen and (max-width: 768px) {
  .mo_use {
    display: block; }
  .pc_use {
    display: none; }
  .top_btn {
    right: 20px; }
  right: 20px; }

@media (max-width: 991.99px) {
  .container_Box {
    width: 100%; }
  .path {
    width: 89%;
    margin: 20px auto 14px;
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
        -ms-flex-order: 2;
            order: 2; }
  .left_nav {
    top: -8px;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
        -ms-flex-order: 1;
            order: 1; }
    .left_nav:after {
      -webkit-transition: .3s;
      -o-transition: .3s;
      transition: .3s;
      position: absolute;
      top: 16px;
      right: 15px;
      -webkit-transform: rotate(-90deg);
          -ms-transform: rotate(-90deg);
              transform: rotate(-90deg);
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      line-height: 1;
      display: block;
      text-align: center;
      font-weight: 900;
      font-family: Font Awesome\ 5 Free;
      content: "\f107";
      color: #fff;
      z-index: 5;
      font-size: 1.375rem; }
    .left_nav.active::after {
      -webkit-transform: rotate(0deg);
          -ms-transform: rotate(0deg);
              transform: rotate(0deg); }
  .container_Box {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
  .left_box,
  .right_content {
    width: 90%;
    max-width: 100%;
    margin: auto; }
  .right_content {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
        -ms-flex-order: 3;
            order: 3; }
    .right_content header {
      padding-bottom: 5px;
      margin-bottom: 10px; }
    .right_content header .fb-like {
      margin-bottom: 10px;
      display: -webkit-inline-box; }
  .main_banner {
    margin-top: 70px; }
  .ariter_bg {
    margin-top: 120px; }
  .main_banner + .ariter_bg {
    margin-top: 0; }
  main[role="main"] {
    display: block;
    margin-top: 0;
    padding-bottom: 40px; }
  .main_banner {
    height: 200px; }
  .left_nav {
    position: relative; }
    .left_nav .title.active + .item_nav {
      -webkit-transition: .4s;
      -o-transition: .4s;
      transition: .4s;
      opacity: 1;
      z-index: 1;
      top: 3.4em; }
  .item_nav {
    width: 100%;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
    position: absolute;
    opacity: 0;
    z-index: -1;
    top: -1em;
    background: #ffffff;
    padding: 7px 3px 3px;
    -webkit-box-shadow: 0 3px 4px rgba(0, 0, 0, 0.12);
            box-shadow: 0 3px 4px rgba(0, 0, 0, 0.12);
    -webkit-border-top-left-radius: 0;
    -webkit-border-top-right-radius: 0;
    -webkit-border-bottom-right-radius: 5px;
    -webkit-border-bottom-left-radius: 5px;
    -moz-border-radius-topleft: 0;
    -moz-border-radius-topright: 0;
    -moz-border-radius-bottomright: 5px;
    -moz-border-radius-bottomleft: 5px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px; }
  .left_nav {
    overflow: initial;
    max-width: 90%;
    margin: auto; }
  .title {
    padding: 14px 20px;
    z-index: 2;
    margin-bottom: 0;
    -webkit-border-top-left-radius: 5px;
    -webkit-border-top-right-radius: 5px;
    -webkit-border-bottom-right-radius: 5px;
    -webkit-border-bottom-left-radius: 5px;
    -moz-border-radius-topleft: 5px;
    -moz-border-radius-topright: 5px;
    -moz-border-radius-bottomright: 5px;
    -moz-border-radius-bottomleft: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px; }
    .title::after {
      display: none; }
    .title h2 {
      margin-bottom: 5px; }
  .right_content header h1 {
    font-size: 1.5rem;
    background-size: 29px 26px;
    font-weight: bold; } }

.g-recaptcha {
  opacity: 0; }

@media screen and (max-width: 768px) {
  main[role="main"] {
    padding-bottom: 40px; }
  .page dt,
  .page dd {
    width: 9%;
    margin: 0px 1%;
    font-size: 1rem; }
    .page dt a,
    .page dd a {
      height: 30px;
      line-height: 30px;
      width: 30px; }
  .page dd.active a {
    font-size: 1.125rem; }
  .editor_Box {
    font-size: 0.9375rem; }
  .right_content .we-content {
    padding: 36px 25px; } }

@media (max-width: 767.99px) {
  .slick-dots li button {
    width: 28px;
    height: 2px; }
  .slick-dots li button:before, .slick-dots li div:before {
    height: 4px; }
  .right_content .case_title h3 {
    font-size: 0.9375rem; }
  .path {
    font-size: 0.8125rem; }
  .editor_Content {
    padding: 40px 8px; }
  .main_banner {
    height: 60px; } }
  @media screen and (max-width: 767.99px) and (max-width: 375px) {
    .main_banner {
      height: 80px; } }

@media (max-width: 767.99px) {
    .main_banner h2 {
      font-size: 1.375rem; }
    .main_banner h1 {
      font-size: 1rem; }
  .case_box h2,
  .from_box h2,
  .qa_item h2 {
    font-size: 1.375rem; } }

/*----------------------header------------------------*/
.header_show {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  background: #ffffff;
  z-index: 10; }
  .header_show .header_box {
    position: relative;
    width: 100%; }
  .header_show .bottom-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-shadow: 0px 1px 4px 0 rgba(13, 5, 9, 0.4);
            box-shadow: 0px 1px 4px 0 rgba(13, 5, 9, 0.4);
    background: url(../images/logo_bg.png?533d3a4a) #f7f7f7 top left no-repeat;
    padding-left: 4.5%; }
    .header_show .bottom-header.fix {
      position: fixed;
      top: 0;
      left: 0;
      height: 67px; }
      .header_show .bottom-header.fix .language_btn {
        display: none; }
  .header_show .nav_item {
    width: 100%;
    height: 58px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
        -ms-flex-align: end;
            align-items: flex-end;
    position: relative; }

.top_header {
  width: 100%;
  height: 42px;
  background: #c60a26;
  position: relative;
  padding: 0 4%;
  background-size: cover; }

.logo {
  width: 288px;
  height: 64px;
  background: url(../images/logo.png?eb3140ae) center left no-repeat;
  text-indent: -10000px;
  background-size: contain;
  -webkit-transition: .4s;
  -o-transition: .4s;
  transition: .4s; }
  .logo a {
    width: 100%;
    height: 100%;
    display: block; }
  .logo:hover {
    opacity: .7; }

/*----------------------nav------------------------*/
.nav_box {
  position: relative;
  top: -5px; }

nav li {
  position: relative;
  list-style: none;
  font-size: 1rem;
  line-height: 18px; }

nav > ul {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center; }
  nav > ul > li a {
    color: #646464;
    text-decoration: none; }
  nav > ul > li:hover > a {
    color: #009a7a;
    font-weight: bold;
    text-decoration: none; }
  nav > ul > li.btn-information > a span:last-child::after {
    display: none; }
  nav > ul > li > a span {
    position: relative;
    border-radius: 5px;
    display: block;
    padding: 6px 23px; }
    @media screen and (max-width: 1366px) {
      nav > ul > li > a span {
        padding: 6px 14px; } }
    nav > ul > li > a span::after {
      content: "";
      display: block;
      width: 1px;
      height: 20px;
      background: #c8c8c8;
      position: absolute;
      right: 0;
      top: 6px; }
  nav > ul > li > a:after {
    display: block;
    margin: 5px auto 0 auto;
    content: "";
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 4.5px 0 4.5px;
    border-color: #c8c8c8 transparent transparent transparent; }

.sub_menu {
  position: absolute;
  display: none;
  background: #fff;
  position: absolute;
  width: 134px;
  top: 54px;
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  border-radius: 5px;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.13);
          box-shadow: 0 0 3px rgba(0, 0, 0, 0.13);
  overflow: hidden; }
  .sub_menu a {
    color: #000;
    text-decoration: none;
    display: block;
    padding: 10px 10px; }
    .sub_menu a:hover {
      color: #ffffff;
      background: #ca1130; }

.dream_btn a:after,
.physician_btn a:after,
.language_btn a:after {
  display: none; }

.physician_btn {
  margin-right: 0 !important; }

/*----------------------language------------------------*/
.nav_box .language_btn {
  display: none; }

.minor_item .language_btn a:first-child {
  margin-right: 11px; }

.minor_item .language_btn a:hover {
  opacity: 0.5; }

.language_btn {
  font-size: 1rem; }
  .language_btn a {
    padding: 0 10px;
    height: 26px;
    line-height: 26px;
    display: inline-block;
    text-align: center;
    border-radius: 99em;
    border: solid 1px #e92b47;
    color: #ffffff;
    text-decoration: none; }
    .language_btn a:hover {
      opacity: 0.5; }

/*----------------------minor_item------------------------*/
.minor_item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  position: absolute;
  right: 0;
  color: #fff; }
  .minor_item a:not(:last-child) {
    margin-right: 10px; }
  .minor_item a:hover {
    opacity: .7; }

.tel_btn {
  text-indent: -10000px;
  display: block; }

.h-tel {
  font-size: 0.875rem;
  color: #fff !important;
  margin-left: 10px; }

.progress-btn {
  background-color: #009a85;
  padding: 12px 18px;
  position: relative;
  color: #fff;
  margin-left: 5px;
  margin-right: 0 !important; }
  .progress-btn img {
    width: 30px;
    margin-right: 8px; }
  .progress-btn:hover {
    opacity: 1 !important;
    color: #fff;
    text-decoration: none;
    background: #3fc2b0; }

.reserve_btn {
  background-color: #ff6d07;
  padding: 12px 18px;
  position: relative;
  color: #fff; }
  .reserve_btn img {
    width: 17px;
    margin-right: 8px; }
  .reserve_btn:hover {
    opacity: 1 !important;
    color: #fff;
    text-decoration: none;
    background: #ff9043; }

.community_btn {
  width: 26px;
  display: block; }
  .community_btn .icon path {
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s; }

/*----------------------nav icon------------------------*/
.menu-wrapper {
  display: none; }

.menu-wrapper {
  display: none;
  position: absolute;
  top: -15px;
  right: 4%;
  bottom: 0;
  margin: auto;
  width: 31px;
  height: 23px;
  cursor: pointer;
  z-index: 10; }

.hamburger-menu,
.hamburger-menu:after,
.hamburger-menu:before {
  width: 31px;
  height: 3px;
  border-radius: 99em; }

.hamburger-menu {
  position: relative;
  -webkit-transform: translateY(10px);
      -ms-transform: translateY(10px);
          transform: translateY(10px);
  background: #a0a0a0;
  -webkit-transition: all 0ms 300ms;
  -o-transition: all 0ms 300ms;
  transition: all 0ms 300ms;
  border-radius: 99px; }
  .hamburger-menu.animate {
    background: rgba(255, 255, 255, 0); }
  .hamburger-menu + span {
    position: absolute;
    top: 29px;
    left: -5px;
    color: #a0a0a0;
    font-size: 0.75rem;
    width: 40px;
    display: block; }

.hamburger-menu:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 10px;
  background: #a0a0a0;
  -webkit-transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 99px; }

.hamburger-menu:after {
  content: "";
  position: absolute;
  left: 0;
  top: 10px;
  background: #a0a0a0;
  -webkit-transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 99px; }

.hamburger-menu.animate:after {
  top: 0;
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 99px; }

.hamburger-menu.animate:before {
  bottom: 0;
  -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
  -webkit-transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 99px; }

.hamburger-menu.animate + span {
  display: none; }

.hamburger-menu.animate:before, .hamburger-menu.animate:after {
  background: #009a85; }

.menu-icon {
  display: none;
  top: 45%;
  right: -0.7em;
  z-index: 100;
  width: 45px;
  height: 39px;
  margin-top: 0;
  -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
  position: absolute; }
  @media (max-width: 768px) {
    .menu-icon {
      display: block; } }

.menu-icon-block {
  display: none;
  position: relative;
  height: 100%;
  margin: auto; }
  .menu-icon-block i {
    position: absolute;
    display: block;
    margin: auto;
    width: 72%;
    height: 2px;
    background-color: #ffffff;
    -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    -o-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    border-radius: 99em; }
    .menu-icon-block i.i1 {
      top: 0%; }
    .menu-icon-block i.i2 {
      top: 25%; }
    .menu-icon-block i.i3 {
      top: 51%; }

.closeBtn {
  display: none; }

@media screen and (max-width: 1187px) {
  nav > ul > li:not(:last-child) {
    margin-right: 10px; }
  nav li {
    font-size: 0.9375rem; }
  nav > ul > li > a span {
    padding: 6px 6px; } }

@media (max-width: 991.99px) {
  .minor_item {
    display: none; }
  .top_header {
    display: none;
    height: 70px; }
  .header_show .bottom-header.fix {
    height: 70px; }
  .header_show .bottom-header {
    height: 70px;
    background: url(../images/logo_bg_m.png?b6741c6a) #f7f7f7 0 0 no-repeat;
    border-bottom: 9px solid #cb102a; }
  .logo {
    height: 43px;
    top: 12px;
    position: relative; }
  .header_show .logo {
    width: 229px;
    padding-bottom: 0; }
  .menu-wrapper {
    display: block; }
  .header_show {
    position: fixed; }
    .header_show .header_box {
      height: 60px; }
  .header_show .nav_item {
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 100%;
    background: rgba(27, 27, 27, 0.62);
    z-index: 9; }
    .header_show .nav_item.fix {
      height: auto; }
      .header_show .nav_item.fix .language_btn {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex; }
      .header_show .nav_item.fix:before {
        display: none; }
  .nav_box .language_btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; }
  nav li {
    font-size: 1.0625rem; }
  nav > ul > li > a span:after {
    display: none; }
  nav > ul {
    width: 100%;
    display: block;
    background: #d1dee8;
    padding: 4em 0 3em 0;
    border-top: solid 1px #009a85;
    overflow-y: scroll;
    height: 102vh; } }
  @media screen and (max-width: 991.99px) and (max-width: 360px) {
    nav > ul {
      height: 100vh; } }

@media (max-width: 991.99px) {
    nav > ul > li {
      width: 90%;
      border-bottom: solid 1px #009a85;
      margin: auto !important; }
      nav > ul > li a {
        padding: 8px;
        width: 100%;
        display: block; }
        nav > ul > li a:after {
          display: none; }
        nav > ul > li a:before {
          width: 10px;
          height: 10px;
          position: relative;
          top: -2px;
          display: inline-block;
          margin-right: 9px;
          content: '';
          background: #009a85;
          background-size: contain;
          border-radius: 99em; }
        nav > ul > li a span {
          display: inline-block;
          background: #fff0; }
      nav > ul > li:hover > a {
        background: #80b7d6; }
        nav > ul > li:hover > a:before {
          background: url(../images/arrw_2.png?5a125209) top left no-repeat;
          background-size: contain;
          -webkit-transform: rotate(90deg);
              -ms-transform: rotate(90deg);
                  transform: rotate(90deg);
          top: 3px;
          left: 2px;
          width: 13px;
          height: 12px; }
        nav > ul > li:hover > a span {
          background: transparent; }
  nav > ul > li:not(:last-child) {
    margin-right: 0; }
  .physician_btn {
    margin: auto !important; }
  .online_btn a,
  .faq_btn a {
    background: none;
    color: #313131;
    padding: 15px; }
  .sub_menu {
    position: relative;
    width: 100%;
    top: 0;
    left: 0;
    -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
            transform: translateX(0);
    border-radius: 0;
    padding: 10px 0 22px 0;
    background: none;
    -webkit-box-shadow: none;
            box-shadow: none; }
    .sub_menu li a:before {
      display: none; }
    .sub_menu a {
      padding: 10px 5px 10px 52px; }
  .closeBtn {
    display: block;
    border: none; }
    .closeBtn a {
      font-size: 20px;
      background: #c60a26;
      text-align: center;
      padding: 0;
      padding: 5px 0 13px;
      width: 89%;
      margin: 37px auto 0 auto;
      color: #fff; }
      .closeBtn a::before {
        width: 24px;
        height: 24px;
        background: url(../images/close_icon.png?df1c2b11) top left no-repeat;
        background-size: cover;
        border-color: none;
        border-color: transparent;
        position: relative;
        top: 4px; }
  .language_btn {
    position: static;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 90%;
    border: none;
    margin-top: 22px !important; }
    .language_btn a:before {
      display: none; }
    .language_btn a {
      width: 39%;
      height: 40px;
      line-height: 40px;
      margin: 0 10px;
      background: #009a85;
      border: none; } }

aside {
  -webkit-transition: .4s;
  -o-transition: .4s;
  transition: .4s;
  opacity: 0;
  position: fixed;
  right: 1%;
  bottom: 2%;
  z-index: 9; }
  aside li {
    list-style: none;
    margin-bottom: 5px; }
  aside.active {
    opacity: 1; }
  aside .top_btn {
    display: block;
    width: 35px;
    height: 35px;
    text-indent: -10000px;
    background: url(../images/top.png?fcacbbc4) top left no-repeat;
    background-size: cover; }

.permanent {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  -webkit-box-shadow: 0px 1px 4px 0 rgba(13, 5, 9, 0.4);
          box-shadow: 0px 1px 4px 0 rgba(13, 5, 9, 0.4);
  background-color: #eeeeee;
  z-index: 8;
  padding: 2% 2% 0 2%;
  margin-bottom: 0; }
  .permanent img {
    width: 100%;
    display: block;
    margin: 0 auto 7px auto; }
  .permanent ul {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center; }
  .permanent li {
    list-style: none;
    margin: 0 2%; }
    @media screen and (max-width: 360px) {
      .permanent li {
        margin: 0 1%; } }
  .permanent a {
    color: #ffffff;
    font-size: 0.875rem;
    text-decoration: none; }

footer {
  width: 100%;
  background: url(../images/footer_bg.png?00f2774e) #fff top left no-repeat;
  padding-top: 39px;
  font-size: 0.875rem;
  line-height: 1.29;
  letter-spacing: 0.7px;
  color: #535353; }
  footer ul {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; }
  footer li {
    list-style: none;
    width: 33.3333%; }
    footer li:not(:last-child) {
      border-right: 1px solid #d2d2d2;
      margin-right: 22px;
      padding-right: 22px; }
  footer h2 {
    font-size: 1.0625rem;
    font-weight: bold;
    margin-bottom: 11px; }
  footer a {
    display: block;
    margin-bottom: 11px; }
  footer .f_map {
    color: #535353;
    text-decoration: none; }
  footer .f_tel {
    color: #f9763c; }

.footer_box {
  width: 90%;
  margin: auto; }

.footer-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 17px; }

.f_logo_add {
  margin-right: 5.1%; }

.f_logo {
  -webkit-transition: .4s;
  -o-transition: .4s;
  transition: .4s;
  text-indent: -10000px;
  display: block;
  width: 271px;
  height: 44px;
  background: url(../images/logo.png?eb3140ae) top left no-repeat;
  background-size: cover;
  margin-bottom: 25px; }
  .f_logo:hover {
    opacity: .7; }

.f_link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }
  .f_link img {
    height: 33px; }
  .f_link a:hover {
    opacity: .7; }
  .f_link a:not(:last-child) {
    margin-right: 10px; }

.copyright {
  text-align: center;
  font-size: 0.86875rem;
  color: #ffffff;
  background-color: #c60a26;
  padding: 14px 0; }

.f_prompt {
  margin-bottom: 12px; }

@media (max-width: 1199.99px) {
  .footer-content {
    display: block; }
  footer ul {
    display: block; }
  footer li {
    width: 100%;
    text-align: center; }
    footer li:not(:last-child) {
      margin-right: 0;
      padding-right: 0;
      padding-bottom: 2%;
      margin-bottom: 5%;
      border-right: none;
      border-bottom: 1px solid #d2d2d2; }
  .f_logo_add {
    margin-right: 0;
    margin-bottom: 21px;
    margin: auto;
    text-align: center; }
  .f_logo {
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    margin: 0 auto 25px auto; }
  .f_link {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-bottom: 20px; }
  aside {
    right: -1px;
    bottom: 12%; } }

@media screen and (max-width: 768px) {
  .permanent {
    display: block; }
  footer {
    margin-bottom: 11%; } }

@media (max-width: 767.99px) {
  footer {
    margin-bottom: 13%; } }

/******************************************/
/*		pages
/******************************************/
.index_Page main[role=main] {
  padding-bottom: 0;
  background: url(../images/team_bg.png?19a897e8) top left; }

.banner a {
  display: block;
  width: 100%;
  height: 406px; }
  @media screen and (max-width: 768px) {
    .banner a {
      height: 0;
      padding-bottom: 29.007%; } }

.banner .slick-prev,
.banner .slick-next {
  -webkit-transition: .4s;
  -o-transition: .4s;
  transition: .4s;
  width: 40px;
  height: 40px;
  background: #ca1130;
  border-radius: 99px;
  margin: -4px 0 0 15px;
  outline: 0;
  z-index: 2; }
  .banner .slick-prev:hover,
  .banner .slick-next:hover {
    background: #aaaaaa; }

.banner .slick-prev {
  left: 2%; }
  .banner .slick-prev::before {
    content: '';
    display: block;
    width: 7px;
    height: 13px;
    background: url(../svg/arrw_l.svg?c1ac7f63);
    margin: -4px 0 0 15px; }

.banner .slick-next {
  right: 2%; }
  .banner .slick-next::before {
    content: '';
    display: block;
    width: 9px;
    height: 14px;
    background: url(../svg/arrw_r.svg?39e90e5d);
    margin: -1px 0 0 15px; }

.banner .slick-dots {
  margin: -31px 0 0;
  max-width: 1051px;
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  text-align: left; }
  .banner .slick-dots li {
    margin: 0 13px; }
    .banner .slick-dots li button:before {
      background: #eeeeee;
      width: 12px;
      height: 12px; }
    .banner .slick-dots li.slick-active button:before {
      background: #b8a68c; }

.in-title {
  color: #ca1130;
  font-size: 1.75rem;
  font-weight: bold;
  margin-bottom: 20px;
  padding-bottom: 12px;
  line-height: 1.5; }
  .in-title a {
    color: #ca1130;
    text-decoration: none; }
    .in-title a:hover {
      opacity: 0.7; }
  .in-title::after {
    display: block;
    content: '';
    width: 115px;
    height: 2px;
    background: #eb6100; }

.in_arrw {
  position: absolute;
  top: 42px;
  right: 0;
  z-index: 1;
  font-size: 1.125rem;
  font-weight: bold; }
  .in_arrw a {
    color: #c60a26;
    text-decoration: none; }
    .in_arrw a:after {
      -webkit-transition: .4s;
      -o-transition: .4s;
      transition: .4s;
      content: '';
      width: 25px;
      height: 25px;
      background: url(../images/btn-arrw.png?ee30adff) no-repeat;
      background-size: cover;
      display: inline-block;
      vertical-align: middle;
      position: relative;
      top: -3px;
      left: 5px; }
    .in_arrw a:hover:after {
      left: 10px; }

.in_item_1 {
  position: relative;
  width: 100%;
  max-width: 1141px;
  margin: 0 auto;
  padding: 23px 0 40px 0; }
  .in_item_1 li {
    list-style: none;
    position: relative;
    float: left;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    position: relative;
    width: 16.65%;
    height: 430px;
    max-width: 680px;
    overflow: hidden; }
    .in_item_1 li.min {
      width: 9%;
      -webkit-filter: blur(3px); }
      .in_item_1 li.min .in_item_content {
        width: 155%;
        opacity: 0; }
    .in_item_1 li.active {
      width: 54%; }
      .in_item_1 li.active .in_item_content {
        width: 100%;
        bottom: 0;
        opacity: 1; }
      .in_item_1 li.active .in_item_content .in_item_text {
        top: 4px; }
    .in_item_1 li a {
      display: block;
      width: 100%;
      height: 100%; }
  .in_item_1 .in_item_img {
    position: absolute;
    width: 100%;
    height: 100%;
    background-position: center left !important; }
  .in_item_1 .in_item_content {
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    position: absolute;
    width: 100%;
    bottom: -45px;
    background: rgba(255, 255, 255, 0.5);
    z-index: 1;
    padding: 14px 10px 13px 10px; }
    .in_item_1 .in_item_content h3 {
      font-size: 1.875rem;
      font-weight: bold;
      padding-bottom: 8px;
      color: #000000;
      line-height: 1.5; }
      .in_item_1 .in_item_content h3::after {
        content: "";
        display: block;
        width: 90px;
        height: 4px;
        background: #26a083; }
    .in_item_1 .in_item_content .in_item_text {
      color: #000000;
      font-size: 1rem;
      height: 43px;
      overflow: hidden;
      line-height: 1.5;
      position: relative;
      top: 8px; }

.in_item_2 {
  max-width: 1141px;
  margin: 0 auto;
  padding: 29px 0 80px 0; }
  .in_item_2 .in-title {
    margin-bottom: 0; }
  .in_item_2 .slider-nav {
    width: 1140px;
    margin: 18px auto 0 auto; }
    @media screen and (max-width: 1140px) {
      .in_item_2 .slider-nav {
        width: 980px; } }
    @media screen and (max-width: 800px) {
      .in_item_2 .slider-nav {
        width: 95%; } }
    .in_item_2 .slider-nav li {
      cursor: pointer;
      -webkit-transition: 0.4s;
      -o-transition: 0.4s;
      transition: 0.4s;
      margin: 0 12px;
      opacity: 0.5; }
      .in_item_2 .slider-nav li:hover {
        opacity: 1; }
    .in_item_2 .slider-nav .slick-current.slick-active li, .in_item_2 .slider-nav .slick-current.slick-center li {
      opacity: 1; }
  .in_item_2 .slick-prev {
    width: 22px;
    height: 30px;
    background: url(../images/btn-l.png?faf7dced) top left no-repeat;
    background-size: cover; }
  .in_item_2 .slick-next {
    width: 22px;
    height: 30px;
    background: url(../images/btn-r.png?cd45c593) top left no-repeat;
    background-size: cover; }

.in_item_3 {
  padding: 0 0 60px 0;
  width: 100%;
  background-size: cover; }

.in_nms_box {
  width: 90%;
  max-width: 1150px;
  margin: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between; }

.in_news_box {
  width: 52%;
  max-width: 572px;
  position: relative; }
  .in_news_box .in_arrw {
    top: 31px;
    right: 13px; }
  .in_news_box .more_btn {
    display: none;
    position: absolute;
    top: 12px;
    right: 0;
    color: #5797BD; }
    .in_news_box .more_btn.active {
      display: block; }
    .in_news_box .more_btn i {
      background: #5797BD; }
      .in_news_box .more_btn i:before, .in_news_box .more_btn i:after {
        background: #5797BD; }
  .in_news_box .news_tag {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    top: 20px;
    font-weight: bold; }
    .in_news_box .news_tag a {
      width: 146px;
      height: 65px;
      border-radius: 14px;
      background: #f6f6f6;
      font-size: 1.375rem;
      color: #b2b2b2;
      padding: 13px 0 0 0;
      text-align: center; }
      .in_news_box .news_tag a:not(:last-child) {
        margin-right: 1%; }
      .in_news_box .news_tag a.active {
        background: #fff; }
  .in_news_box .new_btn {
    display: block;
    text-decoration: none; }
    .in_news_box .new_btn::before {
      content: '';
      display: inline-block;
      width: 21px;
      height: 21px;
      background: url(../images/innws1_1.png?a4a78011) top left no-repeat;
      background-size: cover;
      margin-right: 5px; }
    .in_news_box .new_btn.active {
      color: #cb102a; }
      .in_news_box .new_btn.active::before {
        background: url(../images/innws1_2.png?c62a691c) top left no-repeat; }
  .in_news_box .media_btn {
    display: block;
    text-decoration: none; }
    .in_news_box .media_btn::before {
      content: '';
      display: inline-block;
      width: 25px;
      height: 21px;
      background: url(../images/innws2_1.png?f78fc1b3) top left no-repeat;
      background-size: cover;
      margin-right: 5px; }
    .in_news_box .media_btn.active {
      color: #cb102a; }
      .in_news_box .media_btn.active::before {
        background: url(../images/innws2_2.png?6569fc70) top left no-repeat; }
  .in_news_box .active_btn {
    display: block;
    text-decoration: none; }
    .in_news_box .active_btn::before {
      content: '';
      display: inline-block;
      width: 21px;
      height: 21px;
      background: url(../images/innws3_1.png?cbcf0b41) top left no-repeat;
      background-size: cover;
      margin-right: 5px; }
    .in_news_box .active_btn.active {
      color: #cb102a; }
      .in_news_box .active_btn.active::before {
        background: url(../images/innws3_2.png?e64747e3) top left no-repeat; }
  .in_news_box .news_content {
    position: relative;
    z-index: 1;
    width: 100%;
    display: none;
    background: #fff;
    border-radius: 14px;
    padding: 5% 0 1% 0; }
    .in_news_box .news_content.active {
      display: block; }
  .in_news_box li {
    width: 100%;
    list-style: none;
    position: relative; }
    .in_news_box li a {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: end;
      -webkit-align-items: end;
          -ms-flex-align: end;
              align-items: end;
      text-decoration: none;
      padding: 2% 5%; }
      .in_news_box li a:hover {
        background: #e9f2ff; }
    .in_news_box li:after {
      -webkit-transition: .2s;
      -o-transition: .2s;
      transition: .2s;
      display: block;
      content: '';
      position: absolute;
      top: 41%;
      right: 4%;
      width: 9px;
      height: 16px;
      background: url(../images/arrw_3.png?70cb95c4) no-repeat;
      background-size: contain;
      -webkit-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
              transform: translateX(-50%); }
  .in_news_box .new_title {
    color: #5a7dac;
    font-size: 0.875rem;
    width: 117px;
    margin-right: 23px;
    letter-spacing: 0;
    position: relative;
    top: 4px;
    text-align: center; }
    .in_news_box .new_title .ne1,
    .in_news_box .new_title .ne2,
    .in_news_box .new_title .ne3,
    .in_news_box .new_title .ne4 {
      min-width: 82px;
      border-radius: 0px; }
    .in_news_box .new_title .ne3 {
      color: #fe8e3f;
      background: #fff;
      border: 1px solid #fe8e3f; }
    .in_news_box .new_title .ne4 {
      background: #097c25; }
  .in_news_box .new_time {
    font-weight: bold;
    color: #afafaf;
    margin-bottom: 5px; }
  .in_news_box .in_new_txt {
    width: 60%; }
    .in_news_box .in_new_txt h2 {
      font-size: 1.125rem;
      color: #1a7562;
      height: 1.5em;
      line-height: 1.5;
      overflow: hidden;
      -o-text-overflow: ellipsis;
         text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      /* 第幾行要顯示點點點 */
      -webkit-box-orient: vertical; }
    .in_news_box .in_new_txt p {
      font-size: 0.875rem;
      color: #343434;
      height: 1.5em;
      line-height: 1.5;
      overflow: hidden;
      -o-text-overflow: ellipsis;
         text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      /* 第幾行要顯示點點點 */
      -webkit-box-orient: vertical; }

.in_information_box {
  width: 50%;
  max-width: 526px; }

.in_information_content {
  display: none;
  border-radius: 14px;
  padding: 4.5% 1%;
  background: #fff; }
  .in_information_content img {
    width: 100%;
    max-width: 506px;
    margin: auto;
    display: block; }

.in_item3 #top-menu-ul {
  margin-bottom: 0; }

#top-menu-ul .item_menu_Box li {
  height: 46px; }
  #top-menu-ul .item_menu_Box li:not(:last-child) {
    margin-right: 2%; }
  #top-menu-ul .item_menu_Box li.active a {
    color: #005846; }
  #top-menu-ul .item_menu_Box li a {
    text-decoration: none;
    position: relative;
    display: block;
    line-height: 1;
    letter-spacing: .6px;
    font-size: 1.25rem;
    color: #b2b2b2;
    background: #fff;
    border-radius: 10px;
    width: 110px;
    height: 46px;
    line-height: 46px; }

#top-menu-ul .item_Menu {
  margin: 0 auto;
  overflow: hidden;
  height: 55px;
  position: relative;
  top: -12px; }
  @media screen and (max-width: 586px) {
    #top-menu-ul .item_Menu {
      display: block;
      height: 55px; } }
  #top-menu-ul .item_Menu ::-webkit-scrollbar {
    opacity: 0;
    display: none;
    width: 3px;
    height: 3PX; }
    @media screen and (max-width: 586px) {
      #top-menu-ul .item_Menu ::-webkit-scrollbar {
        display: block; } }
  #top-menu-ul .item_Menu ::-webkit-scrollbar-track {
    display: none;
    opacity: 0;
    background-color: #e8e8e8;
    border-radius: 10px; }
    @media screen and (max-width: 586px) {
      #top-menu-ul .item_Menu ::-webkit-scrollbar-track {
        display: block; } }
  #top-menu-ul .item_Menu ::-webkit-scrollbar-thumb {
    display: none;
    opacity: 0;
    border-radius: 10px;
    background-color: #009a85; }
    @media screen and (max-width: 586px) {
      #top-menu-ul .item_Menu ::-webkit-scrollbar-thumb {
        display: block; } }

#top-menu-ul .item_menu_Box {
  width: 90%;
  margin: auto;
  overflow: hidden;
  overflow-x: scroll;
  position: relative; }

#top-menu-ul .item_menu_Box .item_menu_list {
  white-space: nowrap !important;
  padding-bottom: 0;
  text-align: center;
  font-size: 0;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex; }

.in_item3 #top-menu-ul .item_menu_Box li a div img,
.in_item3 #top-menu-ul .item_Menu a div img {
  width: 100%; }

.flex-direction-nav {
  position: relative;
  display: none; }
  .flex-direction-nav a {
    display: block;
    width: 18px;
    height: 18px;
    position: absolute;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    top: -33px;
    z-index: 5; }
  .flex-direction-nav a.flex-prev {
    content: "";
    width: 0;
    height: 0;
    border-color: transparent transparent transparent transparent;
    border-style: solid;
    border-width: 8.5px 11px 8.5px 0;
    left: 0px;
    display: block;
    width: 30px;
    height: 46px;
    background: url(../images/btn_l.png?7196996c) center no-repeat; }
  .flex-direction-nav a.flex-next {
    content: "";
    width: 0;
    height: 0;
    border-color: transparent transparent transparent transparent;
    border-style: solid;
    border-width: 8.5px 0 8.5px 11px;
    right: 0px;
    display: block;
    width: 30px;
    height: 46px;
    background: url(../images/btn_r.png?89da2e30) center no-repeat; }

.open_flexslider .flex-direction-nav {
  display: block; }

.in_item3 #top-menu-ul a:hover {
  opacity: .7; }

.in_information_content {
  display: none; }
  .in_information_content.active {
    display: block; }

.in_item_4 {
  position: relative;
  width: 90%;
  max-width: 1150px;
  margin: 0 auto 48px auto; }
  .in_item_4 .in_arrw {
    top: 13px; }
  .in_item_4 > ul .slick-list li {
    overflow: hidden;
    border-radius: 5px;
    position: relative;
    list-style: none;
    max-width: 33%;
    max-width: 360px;
    position: relative;
    list-style: none;
    width: 33%;
    height: 0;
    padding-bottom: 78.125%;
    max-width: 360px; }
    .in_item_4 > ul .slick-list li:not(:last-child) {
      margin-right: 3%; }
    .in_item_4 > ul .slick-list li a:hover .in_equipment-img {
      -webkit-transform: scale(1.1);
          -ms-transform: scale(1.1);
              transform: scale(1.1); }
    .in_item_4 > ul .slick-list li a:hover .in_equipment_text {
      opacity: .5; }
  .in_item_4 .slick-slide {
    margin: 0 8px; }
  .in_item_4 .slick-dots li {
    width: 42px;
    height: 7px !important;
    padding: 0;
    border-radius: 0; }
  .in_item_4 .in_equipment-img {
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0; }
  .in_item_4 .in_equipment_text {
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    font-weight: bold;
    width: 85%;
    position: absolute;
    bottom: 5px;
    left: 0;
    color: #000;
    font-size: 1.125rem;
    background: rgba(255, 255, 255, 0.7);
    z-index: 2;
    padding: 9px 2%;
    line-height: 1.4; }

.in_video_box {
  position: relative;
  width: 52%;
  max-width: 596px; }
  .in_video_box .in_arrw {
    top: 13px; }
  .in_video_box li a {
    margin-top: 17px;
    display: block;
    color: #000;
    text-decoration: none;
    height: 3em;
    line-height: 1.5;
    overflow: hidden;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* 第幾行要顯示點點點 */
    -webkit-box-orient: vertical; }
    .in_video_box li a:hover {
      opacity: .5; }
  .in_video_box .slick-slide {
    margin: 0 10px; }
  .in_video_box .youtobe_box {
    position: relative;
    width: 100%;
    height: 180px; }
    .in_video_box .youtobe_box iframe {
      position: absolute;
      width: 100%;
      height: 100%; }

.in_item_5 {
  padding-bottom: 30px; }
  .in_item_5 li {
    list-style: none; }
  .in_item_5 .slick-list {
    margin-bottom: 0; }
  .in_item_5 .slick-dots {
    margin-top: 0px;
    text-align: left; }
    .in_item_5 .slick-dots li {
      background: none !important;
      -webkit-box-shadow: none !important;
              box-shadow: none !important;
      padding: 0 !important;
      border-radius: 0 !important; }
  .in_item_5 .case_list_img {
    padding-bottom: 73.23%; }
  .in_item_5 .in_case_box {
    position: relative;
    width: 52%;
    max-width: 441px; }
    .in_item_5 .in_case_box .in_arrw {
      top: 13px; }
    .in_item_5 .in_case_box .slick-slide {
      margin: 0 10px; }
    .in_item_5 .in_case_box .slick-dots {
      margin-top: 39px; }
    .in_item_5 .in_case_box li {
      border-radius: 15px;
      background: #fff;
      overflow: hidden;
      padding-bottom: 11px;
      -webkit-box-shadow: 2px 2px 3px 1px rgba(0, 0, 0, 0.2);
              box-shadow: 2px 2px 3px 1px rgba(0, 0, 0, 0.2); }
      .in_item_5 .in_case_box li a {
        text-decoration: none; }
        .in_item_5 .in_case_box li a:hover .case_list_img {
          -webkit-transform: scale(1.2);
              -ms-transform: scale(1.2);
                  transform: scale(1.2); }
        .in_item_5 .in_case_box li a:hover .case_list_txt {
          opacity: .7; }
    .in_item_5 .in_case_box .case_list_img {
      -webkit-transition: .4s;
      -o-transition: .4s;
      transition: .4s;
      margin-bottom: 9px; }
    .in_item_5 .in_case_box .case_list_txt {
      width: 90%; }
    .in_item_5 .in_case_box .case_list_txt h3 {
      font-size: 0.875rem;
      font-weight: bold;
      color: #000000;
      height: 1.5em;
      line-height: 1.5;
      overflow: hidden;
      -o-text-overflow: ellipsis;
         text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      /* 第幾行要顯示點點點 */
      -webkit-box-orient: vertical; }
    .in_item_5 .in_case_box .case_list_txt p {
      font-size: 0.875rem;
      color: #e10025;
      height: 1.5em;
      line-height: 1.5;
      overflow: hidden;
      -o-text-overflow: ellipsis;
         text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      /* 第幾行要顯示點點點 */
      -webkit-box-orient: vertical; }

@media screen and (max-width: 1211px) {
  .in_item_1,
  .in_item_2,
  .in_nms_box {
    width: 95%; }
  .in_item_2 {
    padding-bottom: 40px; }
  .in_news_box,
  .in_information_box {
    width: 48%; }
  .in_news_box .news_tag a {
    width: 115px;
    font-size: 0.9375rem; }
  #top-menu-ul .item_menu_Box li a {
    font-size: 0.9375rem; }
  .in_item_5 {
    padding-bottom: 80px; } }

@media (max-width: 991.99px) {
  .index_Page main[role=main] {
    margin-top: 70px; }
  .more_btn {
    font-size: 1rem; }
    .more_btn i {
      width: 25px; }
      .more_btn i:after, .more_btn i:before {
        width: 9px; }
      .more_btn i:before {
        top: -3px; }
      .more_btn i:after {
        bottom: -3px; }
  .in_case_box ul {
    width: 49%; }
  .in_item_2 {
    height: auto; }
  .in_doctors_box {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    .in_doctors_box .in_doctors_list,
    .in_doctors_box .in_doctors_slider {
      width: 100%; }
    .in_doctors_box .in_doctors_slider {
      max-width: 100%; }
      .in_doctors_box .in_doctors_slider .slick-dots {
        padding-right: 0;
        text-align: left;
        padding-left: 15px; }
    .in_doctors_box .slick-slide img {
      width: 100%; }
  .case_list .slick-dots li button {
    width: 30px !important; }
  .in_item_3 .slick-slider,
  .in_item_3 .slick-dots {
    left: 0px; }
  .in_item_3 .video_list .slick-slide li {
    margin: 0;
    max-width: 229px; }
  .in_item_3 .slick-dots li button {
    width: 30px; }
  .in_item_3 .video_txt {
    font-size: 0.9375rem; }
  .in_work_box .case_list_img {
    max-width: 220px; }
  .in_nms_box {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
  .in_news_box {
    width: 100%;
    max-width: 100%; }
  .in_facebook {
    width: 100%;
    max-width: 383px;
    margin: auto; }
    .in_facebook iframe {
      height: 400px; }
  .features_img {
    width: 180px;
    height: 180px; }
  #top-menu-ul {
    margin: 0 auto; }
  .in_item3 #top-menu-ul .item_Menu,
  .in_item3 #top-menu-ul .item_menu_Box li {
    height: 36px; }
  .in_item3 #top-menu-ul .item_Menu,
  .in_item3 #top-menu-ul .item_menu_Box li {
    height: 36px; }
  #top-menu-ul .item_menu_Box li a {
    margin: 0 20px 0 0; }
  .in_item_1,
  .in_item_2,
  .in_nms_box {
    width: 92%; }
  .in_item_1 li {
    width: 33.2%; }
  .slider-for {
    width: 100%; }
    .slider-for img {
      width: 100%; }
  .in_item_2 {
    width: 100%; }
    .in_item_2 .in-title {
      padding-left: 4%;
      margin-bottom: 21px; }
    .in_item_2 .in_arrw {
      right: 5%; }
  .in_item_1 li.active,
  .in_item_1 li.min {
    -webkit-filter: blur(0px);
    width: 33.2%; }
  .in_item_1 li.min .in_item_content {
    opacity: 1; }
  .in_information_box,
  .in_news_box {
    width: 100%;
    max-width: 100%; }
  .in_news_box {
    margin-bottom: 60px; }
  .in_video_box {
    margin-bottom: 40px; }
  .in_video_box,
  .in_item_5 .in_case_box {
    position: relative;
    width: 100%;
    max-width: 100%; }
  .in_case_box ul {
    width: 100%; }
  .banner .slick-prev {
    left: 0%; }
  .banner .slick-next {
    right: 0%; } }

@media (max-width: 575.99px) {
  #top-menu-ul .item_menu_Box li a {
    font-size: 0.9375rem; }
  .banner .slick-dots li {
    margin: 0 7px; }
  .hr_line {
    display: none; }
  .in_case_box .in_case_content {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
  .in_case_box {
    margin: 20px auto 0 auto; }
    .in_case_box ul {
      width: 100%;
      margin-bottom: 0; }
  .in_doctors_box {
    position: relative; }
    .in_doctors_box .in_doctors_list {
      padding: 48px 0 0 0;
      margin: auto;
      width: 91%; }
    .in_doctors_box .more_btn {
      position: absolute;
      top: 10%;
      right: 7.5%;
      z-index: 1; }
  .in_doctors_box .in_doctors_list li a {
    font-size: 1rem;
    padding: 10px 11px;
    height: 38px;
    line-height: 1.5; }
  .in_item_3 .video_list .slick-slide li {
    max-width: 175px; } }
  @media screen and (max-width: 575.99px) and (max-width: 375px) {
    .in_item_3 .video_list .slick-slide li {
      max-width: 159px; } }
  @media screen and (max-width: 575.99px) and (max-width: 360px) {
    .in_item_3 .video_list .slick-slide li {
      max-width: 154px; } }

@media (max-width: 575.99px) {
  .in_work_box .case_list_img {
    max-width: 168px; } }
  @media screen and (max-width: 575.99px) and (max-width: 375px) {
    .in_work_box .case_list_img {
      max-width: 152x; } }

@media (max-width: 575.99px) {
  .in_doctors_box h2 {
    width: 100%;
    height: 25px;
    background-size: contain;
    margin-bottom: 15px; }
  .in_item_3 h2 {
    width: 100%;
    height: 26px;
    background-size: contain;
    margin-bottom: -5px; }
  .in_item_3 h3,
  .in_work_box h3 {
    font-size: 0.9375rem; }
  .in_work_box h2 {
    width: 110px;
    height: 26px;
    background-size: contain;
    margin-bottom: -5px; }
  .in_news_box .new_btn,
  .in_news_box .media_btn {
    width: 85px;
    height: 37px;
    background-size: contain; }
  .in_news_box .new_btn {
    margin-right: 15px; }
  .in_news_box .new_title {
    width: 80px; }
  .in_news_box .in_new_txt {
    width: 58%; }
    .in_news_box .in_new_txt h2 {
      font-size: 0.9375rem; }
    .in_news_box .in_new_txt p {
      font-size: 0.8125rem; }
  .features {
    display: block; }
    .features h3 {
      font-size: 1.0625rem; }
    .features a {
      width: 100%;
      display: block;
      margin: 0 0 34px 0 !important; }
    .features .features_img {
      width: 230px;
      height: 230px;
      margin: 0 auto 11px auto; }
  .in_item_1 {
    padding: 0px 0 20px; }
  .in_item_1 li {
    height: 0;
    padding-bottom: 45%; }
  .in_item_1 .in_item_content h3 {
    font-size: 1rem; }
  .in_item_1 .in_item_content {
    bottom: 0; }
  .in_item_1 .in_item_content .in_item_text {
    display: none; }
  .in-title {
    font-size: 1.375rem;
    margin-bottom: 0; }
    .in-title:after {
      width: 91px; }
  .banner .slick-next {
    right: 2%; }
  .banner .slick-next,
  .banner .slick-prev {
    width: 30px;
    height: 30px;
    margin: -4px 0 0 7px; }
  .banner .slick-prev:before {
    margin: -4px 0 0 10px; }
  .banner .slick-next:before {
    margin: -1px 0 0 11px; }
  .in_arrw {
    font-size: 1rem; }
  .in_arrw a:after {
    width: 19px;
    height: 19px; }
  .in_item_2 {
    padding-bottom: 10px; }
  .in_item_2 .slider-nav {
    margin: -19px auto 0;
    width: 86%; }
  .in_news_box .in_arrw {
    top: -16px; }
  .in_news_box .news_content {
    padding: 6% 0; }
  #top-menu-ul .item_menu_Box li:not(:last-child) {
    margin-right: 2%; }
  #top-menu-ul .item_menu_Box {
    width: 85%; }
  #top-menu-ul .item_menu_Box li a {
    width: 97px;
    height: 40px;
    line-height: 40px; }
  #top-menu-ul .item_menu_Box li {
    height: 57px; }
  #top-menu-ul .item_Menu {
    top: -2px; }
  #top-menu-ul .item_menu_Box li a {
    margin: 0px 10px 0 0; }
  .in_item_4 {
    width: 93%; }
    .in_item_4 .slick-list {
      margin-bottom: 10px; }
  .in_item_4 .in_equipment_text {
    font-size: 0.8125rem;
    padding: 2px 2%; }
  .in_video_box .youtobe_box {
    padding-bottom: 69%; }
  .in_video_box li a {
    font-size: 0.9375rem;
    margin-top: 6px; }
  .in_item_5 {
    padding-bottom: 45px; }
  .in_item_5 .in_case_box .slick-dots {
    margin-top: 20px; } }

.dream_images {
  width: 100%;
  margin-bottom: 20px; }
  .dream_images img {
    display: block;
    width: 100%; }

.qrPage .qr-v {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  line-height: 1.54;
  font-weight: 600; }
  .qrPage .qr-v .qr-img {
    width: 160px; }
  .qrPage .qr-v .qr-text {
    margin-left: 1%;
    width: 77%;
    padding-top: 3%; }

@media screen and (max-width: 767px) {
  .qrPage .qr-v {
    display: block; }
  .qrPage .qr-v .qr-img {
    width: 60%;
    margin: auto; }
  .qrPage .qr-v .qr-text {
    margin-left: 1%;
    width: 100%;
    padding-top: 3%;
    margin-bottom: 9%; } }

.team_Page main[role=main],
.equipment_Page main[role=main] {
  padding-bottom: 0; }

.banner-show {
  width: 100%;
  position: relative; }
  .banner-show .path {
    position: absolute;
    right: 3%;
    bottom: 0;
    color: #fff; }
    .banner-show .path a {
      color: #fff; }

.banner-content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center; }
  .banner-content h1 {
    font-size: 1.875rem;
    color: #ffffff;
    font-weight: bold;
    letter-spacing: 1.5px; }

.team-list {
  width: 100%; }
  .team-list ul {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
  .team-list li {
    position: relative;
    width: 50%;
    list-style: none;
    overflow: hidden;
    background-color: #eeeeee; }
    .team-list li a {
      position: relative;
      width: 100%;
      height: 419px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
          -ms-flex-pack: justify;
              justify-content: space-between;
      text-decoration: none; }
      .team-list li a:hover .team_img {
        -webkit-transform: scale(1.04);
            -ms-transform: scale(1.04);
                transform: scale(1.04); }
      .team-list li a:hover .team-content .team-text {
        opacity: .7; }
  .team-list .team_img {
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    width: 42%;
    height: 100%;
    max-width: 390px;
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
        -ms-flex-order: 2;
            order: 2; }
  .team-list .team-content {
    width: 56%;
    margin: 0 0 0 36px;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
        -ms-flex-order: 1;
            order: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-align-content: center;
        -ms-flex-line-pack: center;
            align-content: center; }
    @media screen and (max-width: 1440px) {
      .team-list .team-content {
        width: 44%; } }
    .team-list .team-content h2 {
      width: 100%;
      font-size: 1.875rem;
      font-weight: bold;
      line-height: 0.87;
      letter-spacing: 1.5px;
      color: #000000;
      margin-bottom: 10px;
      line-height: 1.2; }
    .team-list .team-content h3 {
      width: 100%;
      font-size: 1.375rem;
      font-weight: bold;
      line-height: 1.18;
      letter-spacing: 1.1px;
      color: #c60a26;
      margin-bottom: 13px; }
    .team-list .team-content .team-text {
      width: 100%;
      -webkit-transition: .4s;
      -o-transition: .4s;
      transition: .4s;
      font-size: 0.875rem;
      line-height: 1.71;
      letter-spacing: 0.35px;
      color: #333333;
      height: 5.13em;
      line-height: 1.71;
      overflow: hidden;
      -o-text-overflow: ellipsis;
         text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      /* 第幾行要顯示點點點 */
      -webkit-box-orient: vertical; }

.equipment_Page .team-list li:nth-child(2), .equipment_Page .team-list li:nth-child(3), .equipment_Page .team-list li:nth-child(6), .equipment_Page .team-list li:nth-child(7), .equipment_Page .team-list li:nth-child(10), .equipment_Page .team-list li:nth-child(11), .equipment_Page .team-list li:nth-child(14), .equipment_Page .team-list li:nth-child(15), .equipment_Page .team-list li:nth-child(18), .equipment_Page .team-list li:nth-child(19), .equipment_Page .team-list li:nth-child(22), .equipment_Page .team-list li:nth-child(23), .equipment_Page .team-list li:nth-child(26) {
  background-color: #e9e9e9; }

.equipment_Page .team-list .team-content {
  bottom: 50%;
  width: 54%;
  margin: 0 0 0 36px; }
  .equipment_Page .team-list .team-content h2 {
    font-size: 1.375rem;
    color: #c60a26;
    margin-bottom: 0; }

@media (max-width: 991.99px) {
  .team-list li {
    width: 100%; }
  .equipment_Page .team-list li:nth-child(2), .equipment_Page .team-list li:nth-child(3), .equipment_Page .team-list li:nth-child(6), .equipment_Page .team-list li:nth-child(7), .equipment_Page .team-list li:nth-child(10), .equipment_Page .team-list li:nth-child(11), .equipment_Page .team-list li:nth-child(14), .equipment_Page .team-list li:nth-child(15), .equipment_Page .team-list li:nth-child(18), .equipment_Page .team-list li:nth-child(19), .equipment_Page .team-list li:nth-child(22), .equipment_Page .team-list li:nth-child(23), .equipment_Page .team-list li:nth-child(26) {
    background: #eee; }
  .equipment_Page .team-list li:nth-child(even) {
    background-color: #e9e9e9; } }

@media (max-width: 767.99px) {
  .equipment_Page .team-list .team-content {
    margin: 0 3% 0 4%; }
  .team-list li {
    width: 100%; }
    .team-list li a {
      height: auto; }
  .team-list .team_img {
    width: 43%;
    height: 0;
    padding-bottom: 45%; }
  .equipment_Page .team-list .team-content h2 {
    font-size: 1.0625rem; }
  .team-list .team-content h2 {
    font-size: 1.3125rem; }
  .team-list .team-content h3 {
    font-size: 1.0625rem; }
  .team-list .team-content .team-text {
    font-size: 0.8125rem; } }

@media (max-width: 767.99px) {
  .main_banner {
    height: 115px; }
  .banner-content h1 {
    font-size: 1.375rem; }
  .banner-content .path {
    margin: 12px auto 7px; } }

.case_box {
  width: 100%; }
  .case_box header, .case_box h2 {
    margin-bottom: 0px; }

.case_list {
  margin-top: 5px; }
  .case_list li {
    max-width: 245px;
    list-style: none;
    margin-right: 2.5px; }
    .case_list li a {
      width: 100%;
      height: 100%;
      display: block;
      text-decoration: none; }
      .case_list li a:hover {
        opacity: .5; }

.case_list_img {
  width: 100%;
  max-width: 230px;
  margin-bottom: 18px;
  height: 0;
  padding-bottom: 61.23%; }

.case_list_txt {
  width: 95%;
  margin: auto;
  line-height: 1.3; }
  .case_list_txt h3 {
    font-weight: bold;
    font-size: 1rem;
    color: #c60a26; }
  .case_list_txt p {
    font-size: 0.875rem;
    color: #000;
    height: 1.5em;
    line-height: 1.5;
    overflow: hidden;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    /* 第幾行要顯示點點點 */
    -webkit-box-orient: vertical; }

.cosmetologyPage .item_nav > ul > li.active > a {
  background-color: rgba(255, 255, 255, 0); }

.cosmetologyPage .item_btn a {
  color: #003126; }
  .cosmetologyPage .item_btn a:hover {
    color: #c60a26; }

.cosmetologyPage .hit .item_btn li.active a {
  color: #c60a26;
  background-color: #fff; }

@media (max-width: 767.99px) {
  .case_list li, .case_list_img {
    max-width: 100%; }
  .case_list_txt h3 {
    font-size: 1rem;
    margin-bottom: 0; }
  .case_list_txt p {
    font-size: 0.9375rem; } }

.physician_list {
  margin-bottom: 39px; }
  .physician_list .col-lg-12 {
    margin-bottom: 50px; }
  .physician_list a {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    text-decoration: none; }
    .physician_list a:hover {
      opacity: .7; }
  .physician_list .physician_img {
    width: 36%;
    max-width: 263px;
    border-radius: 3px;
    overflow: hidden;
    height: 0;
    padding-bottom: 23.468%; }
  .physician_list .physician_txt {
    width: 60%; }
    .physician_list .physician_txt .status {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
          -ms-flex-pack: justify;
              justify-content: space-between;
      -webkit-box-align: center;
      -webkit-align-items: center;
          -ms-flex-align: center;
              align-items: center;
      margin-bottom: 11px; }
    .physician_list .physician_txt time {
      font-weight: 600;
      display: block;
      font-size: .875rem;
      color: #333; }
    .physician_list .physician_txt h2 {
      font-size: 1.125rem;
      color: #c60a26;
      margin-bottom: 9px;
      line-height: 1.4;
      font-weight: bold; }
    .physician_list .physician_txt p {
      font-size: 0.875rem;
      color: #000;
      height: 2.8em;
      line-height: 1.4;
      overflow: hidden;
      -o-text-overflow: ellipsis;
         text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      /* 第幾行要顯示點點點 */
      -webkit-box-orient: vertical;
      margin-bottom: 24px; }
    .physician_list .physician_txt .more_btn {
      display: block;
      text-align: center;
      font-size: 0.875rem;
      color: #ffffff;
      line-height: 30px;
      width: 97px;
      height: 30px;
      border-radius: 3px;
      background-color: #aa9371; }

@media (max-width: 991.99px) {
  .physician_list a {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    .physician_list a .physician_img {
      width: 100%;
      margin-bottom: 10px; }
    .physician_list a .physician_txt {
      width: 100%; }
      .physician_list a .physician_txt time {
        font-size: 0.8125rem; }
      .physician_list a .physician_txt h2 {
        font-size: 0.9375rem;
        margin-bottom: 5px; }
      .physician_list a .physician_txt p {
        font-size: 0.875rem;
        margin-bottom: 15px; }
      .physician_list a .physician_txt .more_btn {
        height: 27px;
        line-height: 27px;
        margin: auto; }
  .physician_list .physician_img {
    max-width: 100%;
    padding-bottom: 67%;
    margin-bottom: 10px; } }

.video_box {
  width: 100%;
  margin-bottom: 31px; }

.video_content {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56%; }
  .video_content iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }

.video_Page .right_content header .status {
  display: block;
  text-align: right;
  width: 84%;
  padding-top: 1px; }

.video_Page .col-6 {
  margin-bottom: 34px; }
  .video_Page .col-6 a {
    width: 100%;
    max-width: 350px;
    display: block;
    margin: auto;
    text-decoration: none; }
    .video_Page .col-6 a:hover {
      opacity: .7; }

.list_photo {
  position: relative;
  overflow: hidden;
  width: 36%;
  height: 0;
  max-width: 263px;
  border-radius: 3px;
  padding-bottom: 23.468%; }
  .list_photo .list_img {
    -webkit-transition: .6s;
    -o-transition: .6s;
    transition: .6s;
    -webkit-transform: scale(1);
        -ms-transform: scale(1);
            transform: scale(1);
    height: 0;
    padding-bottom: 55.715%; }
  .list_photo i {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    display: block;
    width: 36px;
    height: 25px;
    background: url(../images/youtobe_icon.png?763c443d) top left no-repeat;
    z-index: 2; }

.ynsa_share_txt {
  width: 100%;
  font-size: 1.125rem;
  color: #000; }
  .ynsa_share_txt time {
    display: block;
    margin-bottom: 10px;
    color: #85b9d7;
    font-size: 1rem; }
  .ynsa_share_txt h2 {
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    letter-spacing: 0.5px;
    height: 2.66em;
    line-height: 1.33;
    overflow: hidden;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* 第幾行要顯示點點點 */
    -webkit-box-orient: vertical;
    color: #aa9371;
    font-size: 1rem; }

@media (max-width: 991.99px) {
  .ynsa_share_txt {
    font-size: 1rem; }
    .ynsa_share_txt time {
      font-size: 0.9375rem; } }

@media (max-width: 991.99px) {
  .video_list .col-6 {
    padding-right: 5px;
    padding-left: 5px;
    margin-bottom: 12px; } }

.ne1 {
  text-align: center;
  min-width: 65px;
  display: inline-block;
  background: #009a85;
  color: #fff;
  font-size: 12px;
  height: 20px;
  line-height: 18px;
  border-radius: 3px;
  padding: 0 1%; }

.ne2 {
  text-align: center;
  min-width: 65px;
  display: inline-block;
  background: #c60a26;
  color: #fff;
  font-size: 12px;
  height: 20px;
  line-height: 18px;
  border-radius: 3px;
  padding: 0 1%; }

.ne3 {
  text-align: center;
  min-width: 65px;
  display: inline-block;
  background: #fe8e3f;
  color: #fff;
  font-size: 12px;
  height: 20px;
  line-height: 18px;
  border-radius: 3px;
  padding: 0 1%; }

.ne4 {
  text-align: center;
  min-width: 65px;
  display: inline-block;
  background: #4698d8;
  color: #fff;
  font-size: 12px;
  height: 20px;
  line-height: 18px;
  border-radius: 3px;
  padding: 0 1%; }

.qa_item {
  margin: 0px 0 60px;
  width: 100%;
  position: relative; }
  .qa_item header, .qa_item h2 {
    margin-bottom: 0px; }

.problem {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 44px; }
  .problem h4 {
    font-weight: bold;
    font-size: 1rem;
    color: #000000;
    margin-right: 5px; }
  .problem select {
    width: 100%;
    max-width: 266px;
    border: solid 1px #d4d4d4;
    background-color: #ffffff; }

.qa_box {
  margin-bottom: 50px; }
  .qa_box .qa-title {
    font-weight: bold;
    color: #009a85;
    font-size: 1.125rem;
    margin-bottom: 11px; }
  .qa_box li {
    margin-bottom: 4px;
    list-style: none; }

.qa_list {
  width: 100%;
  color: #000;
  font-size: 1.125rem;
  line-height: 30px;
  padding: 10px 18px 8px 23px;
  margin-bottom: 0px;
  cursor: pointer;
  background: #e6eef5;
  border-radius: 4px; }
  .qa_list span {
    width: 95%;
    display: inline-block;
    vertical-align: middle; }
  .qa_list:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 10px;
    height: 16px;
    background: url(../images/chevron.png?0e40ee4b) top left no-repeat;
    background-size: cover;
    position: relative;
    top: 0px;
    right: -16px;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s; }
  .qa_list.show:after {
    top: -3px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
            transform: rotate(90deg); }

.qa_content {
  display: none;
  padding: 26px 10px 26px 23px;
  font-size: 1rem;
  letter-spacing: 0.8px;
  line-height: 30px; }

@media (max-width: 991.99px) {
  .qa_list:after {
    right: -26px; }
  .casePage .qa_box {
    margin-bottom: 0; }
  .qa_list {
    line-height: 22px;
    padding: 9px 0px 6px 19px;
    font-size: 0.9375rem; }
    .qa_list span {
      width: 91%; } }
  @media screen and (max-width: 991.99px) and (max-width: 640px) {
    .qa_list span {
      width: 85%; } }

@media (max-width: 991.99px) {
  .qa_content {
    padding: 15px 18px 30px 18px;
    font-size: 0.9375rem;
    line-height: 27px; }
  .problem {
    display: block; }
    .problem h4 {
      margin-bottom: 9px; }
    .problem select {
      max-width: 100%; } }

.msg-box th, .msg-box td {
  font-weight: bold;
  vertical-align: middle;
  padding: 10px 21px;
  border-bottom: 4px solid #fff;
  line-height: 1.6; }

.msg-box table {
  width: 100%; }

.msg-box .table-btn {
  position: relative;
  cursor: pointer; }
  .msg-box .table-btn:after {
    position: absolute;
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 10px;
    height: 16px;
    background: url(../images/chevron.png?0e40ee4b) top left no-repeat;
    background-size: cover;
    top: -3px;
    right: 0;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
    -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
            transform: rotate(90deg); }
  .msg-box .table-btn.active:after {
    top: -3px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    -webkit-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
            transform: rotate(-90deg); }

.msg-box .table-box {
  display: none; }

.msg-box th {
  width: 15%;
  text-align: center;
  background-color: #e6eef5; }

.msg-box td {
  width: 82%; }

.msg-box .msg-list {
  margin-bottom: 40px; }

.msg-box .msitem {
  color: #009a85; }

.message_btn {
  display: block;
  text-align: center;
  width: 150px;
  height: 40px;
  border-radius: 5px;
  background-color: #cb102a;
  font-size: 1.125rem;
  line-height: 40px;
  color: #fff;
  margin-bottom: 75px;
  text-decoration: none; }
  .message_btn:hover {
    opacity: .7;
    color: #fff;
    text-decoration: none; }

@media (max-width: 991.99px) {
  .msg-box th {
    width: 20%; }
  .msg-box td, .msg-box th {
    padding: 10px 12px; }
  .msg-box .table-btn:after {
    top: 10px; } }

.contact_Page .from_box h2, .contact_Page .from_box .from_content h3 {
  display: none; }

.from_box {
  width: 100%; }
  .from_box header, .from_box h2 {
    margin-bottom: 0px; }

.from_content {
  background-color: #fff;
  padding: 60px 50px 40px 50px; }
  .from_content h3 {
    width: 100%;
    position: relative;
    font-size: 1.125rem;
    color: #000;
    margin-bottom: 30px;
    font-weight: bold;
    line-height: 1.3; }

.form-group {
  width: 100%;
  margin: 0;
  margin-bottom: 5px; }

.control-label {
  float: left;
  width: 115px;
  text-align: right;
  font-weight: bold;
  font-size: 1rem;
  padding-top: 7px;
  margin-bottom: 0; }
  .control-label .red {
    color: #bf0d0d !important; }
  .control-label b {
    font-size: 16px;
    margin-right: 3px;
    color: #bf0d0d; }

.check_errors, .list-unstyled li {
  list-style: none;
  font-size: .875rem;
  letter-spacing: .7px;
  color: #bf0d0d;
  margin: 5px 0 7px 5px; }

.form-control:focus {
  border-color: #bf0d0d;
  -webkit-box-shadow: none;
          box-shadow: none; }

.has-danger .col.select_box:after {
  top: 28%; }

.col {
  float: left;
  width: calc(100% - 115px);
  margin-bottom: 0px; }
  .col:after {
    clear: both; }
  .col.select_box {
    position: relative;
    width: 296px; }
    .col.select_box:after {
      position: absolute;
      right: 21px;
      top: 49%;
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 4.5px 0 4.5px;
      border-color: #c8c8c8 transparent transparent transparent;
      display: none; }
  .col textarea,
  .col select,
  .col input {
    margin-left: 5px;
    display: block;
    width: 100%;
    height: 36px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d8d8d8;
    border-radius: 0; }
  .col textarea {
    width: 100%;
    height: 162px; }
  .col .radio input {
    width: 18px;
    height: 18px;
    border: none;
    display: inline-block;
    -webkit-box-shadow: none;
            box-shadow: none;
    vertical-align: sub;
    margin-right: 3px; }
  .col #inputCode {
    display: inline;
    margin-right: 5px; }
  .col .code-pic {
    display: inline;
    margin-right: 5px; }
  .col .change {
    color: #960000;
    font-size: 14px;
    cursor: pointer;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    text-decoration: none; }

.form-button {
  display: block;
  margin: 26px auto 20px auto;
  width: 100%;
  text-align: center; }
  .form-button .btn:first-of-type {
    background-color: none; }
  .form-button .btn {
    font-family: Arial, Verdana, 微軟正黑體;
    display: inline-block;
    margin: 20px 5px 5px 5px;
    width: 150px;
    height: 40px;
    line-height: 40px;
    color: #fff;
    font-size: 20px;
    border: none;
    background: none;
    border-radius: 0;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    border-radius: 5px;
    cursor: pointer; }
    .form-button .btn:hover {
      opacity: 0.7; }
    .form-button .btn:nth-child(1) {
      background: #cb102a; }
    .form-button .btn:nth-child(2) {
      background: #005846; }

.radio {
  margin-top: 10px; }

@media (max-width: 767.99px) {
  .from_box {
    width: 100%; }
  .col.select_box {
    width: 100%; }
  .control-label,
  .col {
    float: none;
    width: 100%;
    display: block;
    padding: 0; }
  .control-label {
    text-align: left;
    margin-bottom: 9px; }
  .form-group {
    margin-bottom: 20px; }
  .from_content {
    padding: 40px 15px 40px; }
  .col input,
  .col select,
  .col textarea {
    width: 100%;
    margin-left: 0; }
  .form-button .btn {
    width: 45%; } }
