.dream_images{
    width: 100%;
    margin-bottom: 20px;
    img{
        display: block;
        width: 100%;
    }
}
.qrPage .qr-v{
    display: flex;
    // align-items: center;
    line-height: 1.54;
    font-weight: 600;
    .qr-img{
       width: 160px;
    }
    .qr-text{
        margin-left: 1%;
        width: 77%;
        padding-top: 3%;
    }
}
@include screen(767px) {
    .qrPage .qr-v {
        display: block;
    }
    .qrPage .qr-v .qr-img{
        width: 60%;
        margin: auto;
    }
    .qrPage .qr-v .qr-text{
        margin-left: 1%;
        width: 100%;
        padding-top: 3%;
        margin-bottom: 9%;
    }
}