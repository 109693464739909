.team_Page main[role=main],
.equipment_Page main[role=main] {
  padding-bottom: 0;
}

.banner-show {
  width: 100%;
  position: relative;

  .path {
    position: absolute;
    right: 3%;
    bottom: 0;
    color: #fff;

    a {
      color: #fff;
    }
  }
}

.banner-content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba($black, 0.2);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: pxToEm(30);
    color: $white;
    font-weight: bold;
    letter-spacing: 1.5px;
  }
}

.team-list {
  width: 100%;

  ul {
    display: flex;
    flex-wrap: wrap;
  }

  li {
    position: relative;
    width: 50%;
    list-style: none;
    overflow: hidden;
    background-color: #eeeeee;

    a {
      position: relative;
      width: 100%;
      height: 419px;
      // padding-bottom: 44.036%;
      display: flex;
      justify-content: space-between;
      text-decoration: none;
      &:hover {
        .team_img {
          transform: scale(1.04);
        }

        .team-content .team-text {
          opacity: .7;
        }
      }
    }
  }

  .team_img {
    transition: .4s;
    width: 42%;
    height: 100%;
    max-width: 390px;
    order: 2;
  }

  .team-content {
    width: 56%;
    margin: 0 0 0 36px;
    order: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    @include screen(1440px) {
      width: 44%;
    }

    h2 {
      width: 100%;
      font-size: pxToEm(30);
      font-weight: bold;
      line-height: 0.87;
      letter-spacing: 1.5px;
      color: #000000;
      margin-bottom: 10px;
      line-height: 1.2;
    }

    h3 {
      width: 100%;
      font-size: pxToEm(22);
      font-weight: bold;
      line-height: 1.18;
      letter-spacing: 1.1px;
      color: #c60a26;
      margin-bottom: 13px;
    }

    .team-text {
      width: 100%;
      transition: .4s;
      font-size: pxToEm(14);
      line-height: 1.71;
      letter-spacing: 0.35px;
      color: #333333;
      @include clamp(1.71, 3)
    }
  }
}

.equipment_Page {
  .team-list li {

    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(10),
    &:nth-child(11),
    &:nth-child(14),
    &:nth-child(15),
    &:nth-child(18),
    &:nth-child(19),
    &:nth-child(22),
    &:nth-child(23),
    &:nth-child(26) {
      background-color: #e9e9e9;
    }
  }

  .team-list .team-content {
    bottom: 50%;
    width: 54%;
    margin: 0 0 0 36px;

    h2 {
      font-size: pxToEm(22);
      color: #c60a26;
      margin-bottom: 0;
    }
  }
}

@include media-breakpoint-down(md) {
  .team-list li {
    width: 100%;
  }

  .equipment_Page {
    .team-list li {

      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(6),
      &:nth-child(7),
      &:nth-child(10),
      &:nth-child(11),
      &:nth-child(14),
      &:nth-child(15),
      &:nth-child(18),
      &:nth-child(19),
      &:nth-child(22),
      &:nth-child(23),
      &:nth-child(26) {
        background: #eee;

      }

      &:nth-child(even) {
        background-color: #e9e9e9;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .equipment_Page .team-list .team-content{
    margin: 0 3% 0 4%;
  }
  .team-list li {
    width: 100%;

    a {
      height: auto;
    }
  }

  .team-list .team_img {
    width: 43%;
    height: 0;
    padding-bottom: 45%;
  }

  .equipment_Page .team-list .team-content h2 {
    font-size: pxToEm(17);
  }

  .team-list .team-content {
    h2 {
      font-size: pxToEm(21);
    }

    h3 {
      font-size: pxToEm(17);
    }

    .team-text {
      font-size: pxToEm(13);

    }
  }
}

@include media-breakpoint-down(sm) {
  .main_banner {
    height: 115px;
  }
  .banner-content h1{
    font-size: pxToEm(22);
  }
  .banner-content .path{
    margin: 12px auto 7px;
  }
}